<template>
	<div class="body">
		<div style="height: 100%;">
			<Nav @new="getNewMsg"></Nav>
			<div class="flex-row" style="background-color: #F4F4F4;height: calc(100vh - 90px);margin-top: 4px;">
				<!-- 左侧部分 -->
				<div class="left-bg">
					<div class="flex-col" style="height: 100%;">
						<div class="title-left-right">
							<div style="flex: 1;">{{QWER('实体列表')}}（{{this.mainData.entity_infos.length}}）</div>
							<el-button type="primary" @click="showRelationDialog = true" v-if="(record_id||leave_id)&&!history">{{QWER('关系图')}}</el-button>
							<el-button type="primary" size="mini" @click="showTour()" >Guide</el-button>
						</div>
		
						
						<div style="overflow-y: auto;height: calc(100vh - 265px);padding-bottom: 10px;">
							<div v-for="(item,index) in entityTreeList">
								<div 
									:class="mainData.entity_infos[mainData.editEntityIndex].entity_name ==item.entity_name?`left_check_bg`:`left_check_no_bg`"
									class="example-css-selector3">
									<el-checkbox :true-label="1" :false-label="0" v-model="item.is_calculate"
										@change="(e)=>checkBoxChange(item,e)"></el-checkbox>
									<div @click="clickEditEntity(item)" class="flex-row flex-1" style="align-items: center;">
										<div class="title_main flex-1">{{item.entity_name}}<span  v-if="item.is_relation_add!=''&&item.is_relation_add!=0&&item.is_relation_add!=undefined">  (Family)</span></div>
										<el-tag type="warning" v-if="item.is_shoutuo==1" size="mini">{{QWER('受托人')}}</el-tag>
										
										<div class="status_bg" v-if="!item.allHas">{{QWER('未确认')}}</div>
										<div class="main_bg" v-if="item.is_main==1">{{QWER('贷款主体')}}</div>
									</div>
									
								</div>
								<div v-for="(item2,index2) in item.childList">
									<div 
										:class="mainData.entity_infos[mainData.editEntityIndex].entity_name ==item2.entity_name?`title_child_checked`:`title_child`">
										
										<el-checkbox :true-label="1" :false-label="0" v-model="item2.is_calculate"
											@change="(e)=>checkBoxChange(item2,e,item)"></el-checkbox>
										<div @click="clickEditEntity(item2)" class="flex-row flex-1" style="align-items: center;">
											<div style="margin-left: 10px;" class="flex-1 title">{{item2.entity_name}}</div>
											<el-tag type="warning" v-if="item2.is_shoutuo==1||item2.is_shoutuo==2" size="mini">{{QWER('受托人')}}</el-tag>
											<div class="status_bg" v-if="!item2.allHas">{{QWER('未确认')}}</div>
											<div class="main_bg" v-if="item2.is_main==1">{{QWER('贷款主体')}}</div>
										</div>
									</div>
								</div>
			
							</div>
			
						</div>
						<footer style="position: absolute;bottom: 0;width: 400px;background-color: #fff">
							<div style="padding: 10px 30px;display: flex;" class="example-css-selector6">
                <el-badge value="New" style="margin-top: 10px;margin-right: 0px;width: 100%" :hidden="!hasNewMsg">
								  <el-button type="primary" style="width: 100%;flex: 1;" @click="toAsk()">{{QWER('客服咨询')}}</el-button>
                </el-badge>
							</div>
						</footer>
					</div>
				</div>
				<!-- 中间部分 -->
				<div class="center-bg" style="height: calc(100vh - 90px);">
					<div class="title-left-right">
						<div style="flex: 1;">Entity Information</div>
					</div>
					<xinxi ref="xinxi" @save="save" @toentity='clickEditEntity2' :parentHasZhuzhai = "parentHasZhuzhai()"
					:typeList="typeList" :typeList2="typeList2" :list='inputChooseList' v-if="mainData.entity_infos.length>0"
						:main_data="mainData.entity_infos[mainData.editEntityIndex].basic_info" :loan_purpose='mainData.entity_infos[mainData.editEntityIndex].loan_purpose'
						 :is_main="mainData.entity_infos[mainData.editEntityIndex].is_main" :is_relation_add ="mainData.entity_infos[mainData.editEntityIndex].is_relation_add"  :childHasZhuzhai='hasZhuzhai()' :eeeIndex='mainData.editEntityIndex'></xinxi>
				</div> 
				<!-- 右侧部分 -->
				<div class="right-bg">
					<div class="flex-col " style="height: calc(100vh -260px);">
						<div class="title-left-right " style="padding-top: 10px;display: flex;flex-direction: column;align-items: baseline">
							<div>{{QWER('总新增贷款')}}：${{getAllTotalMoney()}}</div>
						</div>
						<div style="height: calc(100vh - 385px);overflow-y: auto;" >
							<div class="example-css-selector4" style="padding: 15px;">
								<div v-for="(item,index) in mainData.entity_infos">
									<div v-if="item.is_main==1" class="item-bg">
										<div class="flex-row" style="align-items: center;">
											<div class='flex-1 item-title2' @click="clickEditEntity(item)">{{item.entity_name}}</div>
											<el-button type="primary" size="mini" @click="changeEntityMain(item)">{{QWER('更换主体')}}</el-button>
											<el-button v-if="getMainLength()>1" type="primary"
												class="lease-btn" @click="delMainEntity(item)" icon="el-icon-delete"
												circle style="margin-left: 4px;width: 30px;height: 30px" size="mini"></el-button>
										</div>
										
										<div class="flex-row" v-for="(item2,index2) in item.loan_purpose"
											style="margin-top: 10px;align-items: center;">
											<el-select v-model="item2.purpose_id" style="width: 300px;" v-if="item2.is_relend!=1" placeholder="Select">
												<el-option v-for="item3 in loanTypeList2" :key="item3.id" v-if="checkEntityTypeIds(item,item3)"
													:label="item3.loan_purpose" :value="item3.id">
												</el-option>
											</el-select>
											<!-- 负债转贷 -->
											<el-select v-model="item2.purpose_id" disabled style="width: 300px;" v-if="item2.is_relend==1" clearable placeholder="Select">
												<el-option v-for="item in loanTypeList" :key="item.id"
													:label="item.loan_purpose" :value="item.id">
												</el-option>
											</el-select>
											<MoneyInput v-model="item2.target_total"
												style="margin-left: 10px;width: 220px;" :disabled="item2.is_relend==1" :append="QWER('转贷')" v-if="item2.is_relend==1&&item2.total2==''">
											</MoneyInput>
											<MoneyInput v-model="item2.target_total"
												style="margin-left: 10px;width: 220px;" :disabled="item2.is_relend==1" :append="QWER('增额')" v-if="item2.is_relend==1&&item2.total2!=''">
											</MoneyInput>
											<MoneyInput v-model="item2.target_total" 
												style="margin-left: 10px;width: 220px;" v-if="item2.is_relend!=1" placeholder="New Loan Amount">
											</MoneyInput>
												
												<el-button v-if="item.loan_purpose.length>1&&item2.is_relend==0" type="primary"
													class="lease-btn" @click="delPurposeItem(index,index2)" icon="el-icon-delete"
													circle style="margin-left: 4px;"></el-button>
												<el-button class="lease-btn" type="primary"
													v-if="item.loan_purpose.length==index2+1" @click="addPurposeItem(index)" 
													icon="el-icon-plus" circle style="margin-left: 4px;"></el-button>
										</div>
									</div>
								</div>
								<div style="display: flex;margin-top: 15px;">
									  <el-button type="primary" style="width: 100%;" @click="showAddEntityMain">{{QWER('增加贷款主体')}}</el-button>
								</div>
							</div>
							
						</div>
						<footer style="position: absolute;bottom: 0;width: 400px;">
							<div style="padding-left: 10px;padding-right: 10px;">
								<span >Additional Notes</span>
								<el-input type="textarea" v-model="mainData.remarks" style="margin-top: 6px;" placeholder="E.G., Background, Any expected future income change, Any comments on Loan Proposals." resize="none" :rows="3"></el-input>
							</div>
							<div style="padding: 10px 10px;display: flex;" class="example-css-selector5">
								<el-button type="primary" style="width: 100%;flex: 1;"  @click="showInputEmailDialog">{{QWER('立即评估')}}</el-button>
							</div>
							
							<div style="padding: 0px 10px;display: flex;" class="example-css-selector5">
								<el-button type="primary" style="width: 100%;flex: 1;"  @click="toExit()">Exit</el-button>
								<el-button type="primary" style="width: 100%;flex: 1;"  @click="toSaveDraft()">Save Draft</el-button>
							</div>
							<div style="background: #FFF6EF;padding: 14px;font-size: 14px;">
								<i class="el-icon-warning-outline"></i>
								{{QWER('我们会将详细贷款方案发送到您的邮箱')}}.
								<span style="margin-left: 20px;color: #595959;">v3.12.0</span>
							</div>
						</footer>
			
					</div>
			
				</div>
			</div>
		</div>
		
		<el-dialog
		  :title="QWER('提示')"
		  :visible.sync="showErrorDialog"
		  :before-close="handleErrorClose"
		  width="45%">
		  <div v-for="item in submitErrorList" style="font-size: 14px;color: #DE7C2B;line-height: 22px;margin-left: 20px;margin-top: 5px;">
			  {{item}}
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="showErrorDialog = false">{{QWER('确定')}}</el-button>
		  </span>
		</el-dialog>
		<el-dialog title="" :visible.sync="dialogVisible" :show-close="false" :close-on-click-modal='false'
			width="750px" style="border-radius: 20px;">
			<div>
				<div class="title_main">
					{{QWER('增加贷款主体')}}
				</div>
				<div
					style="background: #FFF6EF;padding: 10px 16px;font-size: 14px;border-radius: 6px;margin-top: 16px;">
					<i class="el-icon-warning-outline"></i>
					{{QWER('增加新的贷款主体可能需要您补充相应信息')}}
				</div>
			</div>
			<el-form ref="ruleForm" :model="tempLoanMainPurpose" :rules='ruleForm'>
				<div class="flex-row">
					<div class="flex-1" style="margin-right: 20px;">
						<el-form-item :label="QWER('请选择贷款主体')" prop="entity_main">
							<div
								style="border-radius: 4px;overflow-y: auto; border: 1px solid #DCDFE6;height: 330px;margin-top: 40px;padding: 8px 20px;">
								<div v-for="(item,index) in entityTreeList">
									<el-radio v-model="tempLoanMainPurpose.entity_main"
										v-if="item.is_main==1||item.is_allow_main==0" disabled
										:label="item">{{item.entity_name}}</el-radio>
									<el-radio v-model="tempLoanMainPurpose.entity_main" v-else
										:label="item">{{item.entity_name}}</el-radio>
									<div v-for="(item2,index2) in item.childList" style="padding-left: 30px;">
										<el-radio v-model="tempLoanMainPurpose.entity_main"
											v-if="item2.is_main==1||item2.is_allow_main==0" disabled
											:label="item2">{{item2.entity_name}}</el-radio>
										<el-radio v-model="tempLoanMainPurpose.entity_main" v-else
											:label="item2">{{item2.entity_name}}</el-radio>
									</div>
								</div>
							</div>
						</el-form-item>
					</div>
					<div class="flex-1">
						<el-form-item :label="QWER('请选择贷款用途')" prop="purpose_id">
							<el-select v-model="tempLoanMainPurpose.purpose_id" placeholder="Select"
								style="width: 100%;">
								<el-option v-for="item in loanTypeList2" :key="item.id" :label="item.loan_purpose" v-if="checkEntityTypeIds2(item)"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item :label="QWER('请输入您期望贷款的金额')" style="width: 100%;" prop="target_total">
							<MoneyInput v-model="tempLoanMainPurpose.target_total" ></MoneyInput>
						</el-form-item>
						<div style="font-size: 14px;color: #DE7C2B;line-height: 22px;">*{{QWER('如不填写，系统将按照最大可能性测算')}}</div>
						<div class="dialog-footer" style="margin-top: 100px;width: 100%;text-align: right;">
							<el-button @click="dialogVisible = false">{{QWER('取消')}}</el-button>
							<el-button type="primary" @click="confirmAddEntityMain">{{QWER('确定')}}</el-button>
						</div>
					</div>
				</div>
			</el-form>
		</el-dialog>
		<el-dialog title="" :visible.sync="dialogChangeVisible" :show-close="false" :close-on-click-modal='false'
			width="750px" style="border-radius: 20px;">
			<div>
				<div class="title_main">
					{{QWER('更换贷款主体')}}
				</div>
			</div>
			<el-form ref="ruleForm" :model="tempChange" :rules='ruleForm'>
				<div class="flex-row">
					<div class="flex-1">
						<el-form-item :label="QWER('请选择新的贷款主体')" prop="entity_new">
							<div
								style="border-radius: 4px;overflow-y: auto; border: 1px solid #DCDFE6;height: 330px;margin-top: 40px;padding: 8px 20px;">
								<div v-for="(item,index) in entityTreeList">
									<el-radio v-model="tempChange.entity_new"
										v-if="item.is_main==1||item.is_allow_main==0" disabled
										:label="item">{{item.entity_name}}</el-radio>
									<el-radio v-model="tempChange.entity_new" v-else
										:label="item">{{item.entity_name}}</el-radio>
									<div v-for="(item2,index2) in item.childList" style="padding-left: 30px;">
										<el-radio v-model="tempChange.entity_new"
											v-if="item2.is_main==1||item2.is_allow_main==0" disabled
											:label="item2">{{item2.entity_name}}</el-radio>
										<el-radio v-model="tempChange.entity_new" v-else
											:label="item2">{{item2.entity_name}}</el-radio>
									</div>
								</div>
							</div>
						</el-form-item>
						<div class="dialog-footer" style="margin-top: 20px;width: 100%;text-align: right;">
							<el-button type="primary" @click="dialogChangeVisible = false">{{QWER('取消')}}</el-button>
							<el-button type="primary" @click="confirmChangeEntityMain">{{QWER('确定')}}</el-button>
						</div>
					</div>
				</div>
			</el-form>
		</el-dialog>
		<el-dialog
		  :title="QWER('提示')"
		  :visible.sync="showConfirmDialog"
		  width="30%">
		  <div>
			  <el-form label-position="top" label-width="80px" :model="mainData"
			  	ref="ruleForm1" :rules='ruleForm'>
				<el-form-item :label="QWER('请输入用于接收评测结果的邮箱地址')" prop="email"  style="margin-left: 20px;">
					<el-input v-model="mainData.email" type="email" placeholder="Enter"></el-input>
				</el-form-item>
			</el-form>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="showConfirmDialog = false">{{QWER('取消')}}</el-button>
		    <el-button type="primary" @click="submitData">{{QWER('确定')}}</el-button>
		  </span>
		</el-dialog>
    <el-dialog
        :visible.sync="showResultDialog"
        width="550px">
      <div>
        <div style="padding-bottom: 10px">{{showResultData.msg}}</div>
        <div v-for="item in showResultData.data.list" class="result-item-name" @click="onClickEditResult(item)">
          - {{item}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="showResultDialog = false">{{QWER('确定')}}</el-button>
		  </span>
    </el-dialog>
		<!-- 关系图 -->
		<el-dialog title="" :visible.sync="showRelationDialog" :show-close="true"
			width="1400px" style="border-radius: 20px;">
			<relation :main_data='mainData' ref='relation' v-if="showRelationDialog"></relation>
		</el-dialog>
	</div>
</template>

<script>
	import { getUserInfo } from "@/utils/myAuth";
	import Nav from "@/components/Nav/Nav.vue";
	import xinxi from "./xinxi.vue";
	import relation from './relation.vue'
	import Shepherd from 'shepherd.js';
	import 'shepherd.js/dist/css/shepherd.css';
	import MoneyInput from '@/components/MoneyInput.vue';
  import {
    getEntityTypes,
    getLoanPurposes,
    getLoanResult,
    getEstimateDetail,
    getPawnType,
    getIncomeList,
    saveDraft, deleteDraft
  } from "@/api/loans/loans"
	export default {
		components: {
			Nav,
			xinxi,
			relation,
			MoneyInput
		},
		props: [
			'data'
		],
		data() {
			return {
        hasNewMsg:false,
				showRelationDialog:false,
				history:false,
				record_id:'',
				leave_id:'',
				userInfo: {
				  id: "",
				},
				inputChooseList:[],
				cancelList:[],
				submitErrorList:[],
				showTime:5000,
				showErrorDialog:false,
        showResultDialog:false,
        showResultData:{
          data:{
            list:[]
          },
          msg:''
        },
				is_loading_data:false,
				showConfirmDialog:false,
				standNum: 25, //大于25的零界点
				pawnTypeList:[],
				typeList: [],
				typeList2: [],
				loanTypeList: [],
				loanTypeList2: [],
				dialogVisible: false,
				dialogChangeVisible:false,
				totalEntity: 0,
				entityTreeList: [], //实体树状列表
				entityTreeListTemp: { //树状列表item模板
					is_main: "", //是否为主贷 1：是 0：否
					is_relation_add:0,//个人联名实体 1是 0否 是否通过个人关系添加的个人联名实体 
					is_shoutuo:false,
					is_calculate: 1, //是否纳入计算 1：是 0：否（勾选了就是 1）
					entity_type_id: "", //实体类型（个人、企业、家庭信托、单元信托）-传 id
					entity_name: "", //实体名称
					hasFuzhai:false,
					allHas: false,
					index: 0,
					childList: []
				},
				mainData: {
					email: "",
					remarks:"",
					firstvalue:'',
					is_get_guaranteable: 0,
					is_reorganization: 0,
					main_loan_name: '',
					editEntityIndex: 0,
					loan_type: "", //贷款类型 1：住宅贷款（住宅贷款） 2：商业贷款（投资贷款，经营贷款）
					loan_main: [], //贷款主体 数据在提交的时候由entity_infos中读取
					//贷款实体信息
					entity_infos: []
				},
				tempLoanMainPurpose: { //新增贷款主体时
					entity_main: "",
					purpose_id: "", //贷款用途id
					target_total: "", //贷款金额 1000000
					is_relend: 0, //是否为转贷，1是，0否
					pawn_type:'',
					evaluate_value:'',
					building_cost:'',
					rent_total:'',
					presale_total:''
				},
				tempChange: { //新增贷款主体时
					entity_old: "",
					entity_new: "",
				},
				ruleForm: {
					entity_main: [{
						required: true,
						message: this.QWER('请选择贷款主体'),
						trigger: 'change'
					}],
					entity_old: [{
						required: true,
						message: this.QWER('请选择原贷款主体'),
						trigger: 'change'
					}],
					entity_new: [{
						required: true,
						message: this.QWER('请选择新的贷款主体'),
						trigger: 'change'
					}],
					email: [{
						required: true,
						message: this.QWER('请输入邮箱地址'),
						trigger: 'blur'
					},
					 { type: 'email', message: this.QWER('请输入正确的邮箱地址'), trigger: ['blur', 'change'] },
					 ],
					purpose_id: [{
						required: true,
						message: this.QWER('请选择贷款用途'),
						trigger: 'blur'
					}],
					target_total: [{
						required: true,
						message: this.QWER('请输入贷款金额'),
						trigger: 'blur'
					}],
				},
			
				entityInfoTemp: {
					
					loan_purpose: [],
					hasFuzhai:false,//是否是有无收入有负债
					is_main_change:false,//是否未负债转贷实体
					is_family:0,
					is_main: "", //是否为主贷 1：是 0：否
					is_calculate: 1, //是否纳入计算 1：是 0：否（勾选了就是 1）
					is_calculate_able: 1, //是否可以纳入计算 1：是 0：否（勾选了就是 1）
					is_allow_mian:1,//是否可以成为主体
					entity_type_id: "", //实体类型（个人、企业、家庭信托、单元信托）-传 id
					entity_name: "", //实体名称
					allHas: false,
					basic_info_has: false,
					basic_info: {
						xintuolist:[],
						target:{
							target:{}
						},
						entity_name: "",
						entity_type_id: '',
						director_info_list: [{
							name: ''
						}], //针对企业才有
						director_info: "", //针对企业才有
						share_info: [ //股东/合伙人信息-针对企业和合伙企业才有
							{
								"is_strong": '0',
								"entity_type_id": "", //股东类型（个人、企业、家庭信托、单元信托）-传 id
								"entity_name": "", //股东 1/合伙人 1
								"share_ratio": '' //0.25
							}
						],
						share_other_info: [ //股东/董事/合伙人/受益人持有的其他实体信息-针对企业、合伙企业、信托才有
							{
								"is_strong": '0',
								"entity_names_list": [],
								"entity_names": "", //董事 1/合伙人 1,董事 2/合伙人 2,股东 1,股东 2..... 多个英文逗号隔开
								"entity_type_id": "", //持有其他实体类型-传 id
								"entity_name": "", //持有其他实体名称
								"share_ratio": "" //0.25
							},
						],
						entity_other_info: [ //当前实体持有其他实体信息-针对企业、合伙企业、信托才有
							{
								"is_strong": '0',
								"entity_type_id": "", //实体类型（个人、企业、家庭信托、单元信托）-传 id
								"entity_name": "", //当前实体持有其他实体的实体名称
								"share_ratio": '' //0.25
							}
						],
						spouse_name: "",
						
						benef_info: [ //受益人/持有人信息-针对信托才有
							{
								"entity_name": "", //受益人 2
								"entity_type_id": "", //股东类型（个人、企业、家庭信托、单元信托）-传 id
                "share_ratio": '' //0.25
							}
						],
						trustee_info: { //托管人信息-针对信托才有,托管人只可能一个
							entity_type_id: "", //托管人类型（个人、企业）-传 id
							entity_name: "" //托管人名称
						},
						trustee_director_info_list: [{
							name: ''
						}],
						trustee_director_info: "", //针对信托并且托管人类型为企业才有  托管人董事信息，多个英文逗号隔开
						trustee_share_info: [ //托管人股东信息-针对信托并且托管人类型为企业才有
							{
								"is_strong":'0',
								"entity_type_id": "", //股东类型（个人、企业）-传 id
								"entity_name": "", //股东名称
								"share_ratio": '', //0.25
							}
						],
						trustee_share_other_info: [ //受托人的股东及董事持有其他实体信息
							{
								"is_strong":'0',
								"entity_names_list": [],
								"entity_names": "", //董事 1/股东 1 ,董事 2/股东 2,股东 1,股东 2..... 多个英文逗号隔开
								"entity_type_id": "", //持有其他实体类型-传 id
								"entity_name": "", //持有其他实体名称
								"share_ratio": "" //0.25
							}
						],
						expenditure:{
							credit_limit:"",
						    decs:"",
						    other:"",
						    of_living:"",
						    rental_total:"",
							investment_property_loan_interest:"",
							balance:''
						},
						income_info: [
							{
								"income_id":"",
								"income_name":"",
								"total":"",
							}
						],
						income_info_ebeta:[],
						people_count:'',
						debt_info: [{
							purpose_id: "", //债务类型
							total: "", //债务金额
							is_relend: 0 ,//是否转贷
							pawn_type:'',
							evaluate_value:'',
							building_cost:'',
							rent_total:'',
							presale_total:'',
              interest_rate:"",
							
						}],
						pawn_info: [{
							pawn_type: "",
							total: "",
							is_relend:1,
              rental_income:'',
              interest_rate:''
						}],
						loanDaikuangNewZichan:[],//根据贷款类型项生成的新增资产
						new_pawn_info: [{
							pawn_type: "",
							income_total: "",
							total: "",
							index:-1,
							is_relend:1,
              rental_income:''
						}]
					},
					 expenditure:{
						credit_limit:"",
					    decs:"",
					    other:"",
					    of_living:"",
					    rental_total:"",
						investment_property_loan_interest:""
					},
					income_info_ebeta:[],
					income_info: [
							{
								income_id:"",
								income_name:"",
								total:"",
							}
						],
					people_count:'',
					debt_info: [{
						purpose_id: "", //债务类型
						total: "", //债务金额
						is_relend: 0 ,//是否转贷
						pawn_type:'',
						evaluate_value:'',
            interest_rate:"",
						building_cost:'',
						rent_total:'',
						presale_total:''
					}],
					pawn_info: [{
						pawn_type: "",
						total: "",
						is_relend:1,
            rental_income:'',
            interest_rate:""
					}],
					new_pawn_info: [{
						pawn_type: "",
						income_total: "",
						total: "",
						index:-1,
						is_relend:1,
            rental_income:''
					}],
					build_pawn_ino:[]
				}
			};
		},
		watch: {
			
		},
		created() {
			
			let params = this.$route.params
			let query = this.$route.query
			if (query.leave_id != '' && query.leave_id != undefined) {
				this.leave_id = query.leave_id
				this.getRecordData('',query.leave_id)
			}
			if(query.history!=undefined){
				this.history = query.history
			}

			if (query.record_id != '' && query.record_id != undefined) {
				this.record_id = query.record_id
				this.getRecordData(query.record_id,'')
			}else if(query.draft!=undefined){
        this.afterSaveData()
        loading.close()
        this.getTypeList()
      } else {
				if (params.entity_name != "" && params.entity_name != undefined) {
					this.mainData.firstvalue = params.firstvalue
					this.mainData.is_reorganization = params.is_reorganization
					this.mainData.loan_type =( params.loan_type == 100003|| params.loan_type == 1000031) ? 1 : 2
					if(params.is_family==1){
						
						let temp = JSON.parse(JSON.stringify(this.entityInfoTemp))
						temp.entity_type_id = 6
						temp.is_family=1
						temp.is_relation_add = params.entity_name
						temp.entity_name = params.entity_name+"&"+params.spouse_name
						temp.is_main = 1//是否为主贷
						temp.is_calculate = 1//默认勾选
						temp.basic_info.entity_type_id = 6
						temp.basic_info.entity_name = params.entity_name+"&"+params.spouse_name
						temp.basic_info.share_info=[]
						temp.basic_info.share_info.push({
							"is_strong": 0,
							"is_strong2": 1,
							"entity_type_id": 2, //股东类型（个人、企业、家庭信托、单元信托）-传 id
							"entity_name": params.entity_name, //股东 1/合伙人 1
							"share_ratio": '' //0.25
						})
						
						temp.basic_info.share_info.push({
							"is_strong": 0,
							"is_strong2": 1,
							"entity_type_id": 2, //股东类型（个人、企业、家庭信托、单元信托）-传 id
							"entity_name": params.spouse_name, //股东 1/合伙人 1
							"share_ratio": '' //0.25
						})
						this.mainData.entity_infos.push(temp)
						
						let temp2 = JSON.parse(JSON.stringify(this.entityInfoTemp))
						temp2.entity_type_id = 2
						temp2.entity_name = params.entity_name
						
						temp2.basic_info.entity_name = params.entity_name
						temp2.basic_info.entity_type_id = 2
						temp2.basic_info.spouse_name =  params.spouse_name
						temp2.basic_info.is_strong = 1
						temp2.is_main = 0//是否为主贷
						temp2.is_calculate = 1//默认勾选
						this.mainData.entity_infos.push(temp2)
						// //添加至实体列表
						let temp3 = JSON.parse(JSON.stringify(this.entityInfoTemp))
						temp3.entity_type_id = 2
						temp3.entity_name = params.spouse_name
						
						temp3.basic_info.entity_name = params.spouse_name
						temp3.basic_info.entity_type_id = 2
						temp3.basic_info.spouse_name =  params.entity_name
						temp3.basic_info.is_strong = 1
						temp3.is_main = 0//是否为主贷
						temp3.is_calculate = 1//默认勾选
						this.mainData.entity_infos.push(temp3)
					}else{//非家庭
						//添加至实体列表
						this.addEntityInfoItem({
							entity_name:params.entity_name,
							entity_type_id:params.entity_type_id,
							is_main:1
						})
					}
					
					if(params.is_relend==1){
						this.mainData.entity_infos[0].basic_info.debt_info = params.debt_info
						this.mainData.entity_infos[0].basic_info.pawn_info=[]
						params.debt_info.map(res=>{
							this.mainData.entity_infos[0].basic_info.pawn_info.push({
									"pawn_type": "",
									"total": "",
									"is_relend":1,
                  "rental_income":'',
                  "interest_rate":''
								})
						})
						
						this.mainData.entity_infos[0].debt_info = params.debt_info
						params.debt_info.map(res => {
							if(res.purpose_id!=''){
								let temp = {
									purpose_id: res.purpose_id,
									target_total: res.total,
									is_relend: 1,
									pawn_type:'',
									evaluate_value:'',
                  interest_rate:"",
									building_cost:'',
									rent_total:'',
									presale_total:'',
                  rental_income:''
								}
								this.mainData.entity_infos[0].loan_purpose.push(temp)
							}
						})
					}else{
						this.mainData.entity_infos[0].loan_purpose = [{
							purpose_id: params.loan_type,
							is_relend: 0, //非转贷
							target_total: params.target_total,
							pawn_type:'',
							evaluate_value:'',
							building_cost:'',
							rent_total:'',
							presale_total:''
						}]
					}
					//更新树图
					this.afterSaveData()
					
				} else {
					this.getData()
				}
				this.getTypeList()
				
				getIncomeList({type:2,is_get_ebitda:1}).then(res=>{
					let incomeList  = []
					res.data.map(ii=>{
						let tt= {
							income_id:"",
							income_name:"",
							total:"",
							is_ebt:1,
						}
						tt.income_id = ii.id
						tt.income_name =ii.name
						
						incomeList.push(tt)
					})
				
					this.mainData.entity_infos[0].income_info_ebeta = incomeList
					this.mainData.entity_infos[0].basic_info.income_info_ebeta = incomeList
				})
				
			}
			const user = JSON.parse(getUserInfo());
			this.userInfo = user
			if(this.mainData.email==''&&this.userInfo){
				this.mainData.email =this.userInfo.email
			}
			setTimeout(()=>{
				this.initTour()
			},500)
			getIncomeList({type:2,is_get_ebitda:1}).then(res=>{
				let incomeList  = []
				res.data.map(ii=>{
					let tt= {
						income_id:"",
						income_name:"",
						total:"",
						is_ebt:1,
					}
					tt.income_id = ii.id
					tt.income_name =ii.name
					
					incomeList.push(tt)
				})
				this.entityInfoTemp.income_info_ebeta = incomeList
				this.entityInfoTemp.basic_info.income_info_ebeta = incomeList
			})
			
		},
		methods: {
      getNewMsg(data){
        this.hasNewMsg = data
      },
			toExit(){
				this.$confirm(this.QWER('All data will be lost if ‘Confirm’ is clicked!  To save your progress, please click calculate.'), this.QWER('提示'), {
				          confirmButtonText: this.QWER('确定'),
				          cancelButtonText: this.QWER('取消'),
				          type: 'warning'
				        }).then(() => {
							 window.location.href = 'http://www.pancakeloan.com';
				        }).catch(() => {
				                   
				        });
			},
			//右侧贷款类型判断是否满足实体类型
			checkEntityTypeIds(entity,item){
				if(item.entity_type_ids.includes(entity.entity_type_id)){
					return true
				}else{
					return false
				}
			},
			//添加贷款主体时判断贷款类型与实体类型
			checkEntityTypeIds2(item){
				let entity_name = this.tempLoanMainPurpose.entity_main.entity_name
				let has = false
				this.mainData.entity_infos.map(res=>{
					if(res.entity_name ==entity_name){
						if(item.entity_type_ids.includes(res.entity_type_id)){
							has = true
						}
					}
				})
				return has;
			},
			showTour(){
				sessionStorage.setItem("tour",'')
				this.initTour()
			},
			initTour(){
				const tour = new Shepherd.Tour({
				  useModalOverlay: true,
				  defaultStepOptions: {
				    classes: 'shepherd-theme-custom ',
				    scrollTo: true
				  }
				});
				tour.addStep({
				  id: 'example-step',
				  text: '<h3>Step1</h3><br/>Start with income details and use the button to add/remove as needed. The more details provided in all fields, the more accurate outcome',
				  attachTo: {
				    element: '.example-css-selector1',
				    on: 'right'
				  },
				  classes: 'example-step-extra-class4',
				  buttons: [
					  {
					    text: 'Ignore(1/5)',
						classes: 'el-button el-button-left1',
					    action: tour.complete
					  },
					  
				    {
				      text: 'NEXT',
					  classes: 'el-button el-button--primary',
				      action: tour.next
				    }
				  ]
				});
				tour.addStep({
				  id: 'example-step',
				 text: "<h3>Step2</h3><br/>Don’t forget to click ‘Save’ whenever leaving the page",
				  attachTo: {
				    element: '.example-css-selector2',
				    on: 'bottom'
				  },
				  classes: 'example-step-extra-class2',
				  buttons: [
					  {
					    text: 'Ignore(2/5)',
					  	classes: 'el-button el-button-left',
					    action: tour.complete
					  },
					  {
					    text: 'BACK',
					  	classes: 'el-button el-button--primary',
					    action: tour.back
					  },
				    {
				      text: 'NEXT',
				      classes: 'el-button el-button--primary',
				      action: tour.next
				    }
				  ]
				});
				tour.addStep({
				  id: 'example-step',
				 text: "<h3>Step3</h3><br/>Tick/untick box to include/exclude the entity in the calculation unless a strong correlation exists. Click entity marked ‘Unsaved’ to check through and save the information",
				  attachTo: {
				    element: '.example-css-selector3',
				    on: 'bottom'
				  },
				  classes: 'example-step-extra-class1',
				  buttons: [
					  {
					    text: 'Ignore(3/5)',
					  	classes: 'el-button el-button-left',
					    action: tour.complete
					  },
					  {
					    text: 'BACK',
					  	classes: 'el-button el-button--primary',
					    action: tour.back
					  },
				    {
				      text: 'NEXT',
				      classes: 'el-button el-button--primary',
				      action: tour.next
				    }
				  ]
				});
				tour.addStep({
				  id: 'example-step',
				 text: "<h3>Step4</h3><br/>Click buttons to amend the proposal. The blocked content can only be amended  by original input.",
				  attachTo: {
				    element: '.example-css-selector4',
				    on: 'bottom'
				  },
				  classes: 'example-step-extra-class1',
				  buttons: [
					  {
					    text: 'Ignore(4/5)',
					  	classes: 'el-button el-button-left',
					    action: tour.complete
					  },
					  {
					    text: 'BACK',
					  	classes: 'el-button el-button--primary',
					    action: tour.back
					  },
				    {
				      text: 'NEXT',
				      classes: 'el-button el-button--primary',
				      action: tour.next
				    }
				  ]
				});
				tour.addStep({
				  id: 'example-step',
				 text: "<h3>Step5</h3><br/>Click 'Calculate' and follow the prompt to provide an email address as the default login for receiving all outcomes and records.",
				  attachTo: {
				    element: '.example-css-selector5',
				    on: 'bottom'
				  },
				  classes: 'example-step-extra-class2',
				  buttons: [
					  {
					    text: 'Ignore(5/5)',
					  	classes: 'el-button el-button-left',
					    action: tour.complete
					  },
					  {
					    text: 'BACK',
					  	classes: 'el-button el-button--primary',
					    action: tour.back
					  },
				    {
				      text: 'NEXT',
				      classes: 'el-button el-button--primary',
				      action: tour.next
				    }
				  ]
				});
				tour.addStep({
				  id: 'example-step',
				 text: "<h3>Help</h3><br/>Click the button here for any questions or comments",
				  attachTo: {
				    element: '.example-css-selector6',
				    on: 'bottom'
				  },
				  classes: 'example-step-extra-class2',
				  buttons: [
					  {
					    text: 'BACK',
					  	classes: 'el-button el-button--primary',
					    action: tour.back
					  },
				    {
				      text: 'START',
				      classes: 'el-button el-button--primary',
				      action: tour.complete
				    }
				  ]
				});
				let showTour = sessionStorage.getItem("tour")
				if(!showTour){
					tour.start()					
				}
				['complete', 'cancel'].forEach((event) =>
				  tour.on(event, () => {
				    sessionStorage.setItem("tour",1)
				  })
				);
			},
			formatMoney(value) {
			  if (!value) return '';
			  value = value.toString().replace(/,/g, ''); // 去除已有千分位
			  const parts = value.split('.');
			  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // 添加千分位
			  return parts.join('.');
			},
			QWER(message) {
				let temp =  this.$t(message)
			   return temp
			},
			//暂时弃用
			parentHasZhuzhai(){
				//个人联名存在100004 个人自宅贷款，其下所有个人显示
				let entity = this.mainData.entity_infos[this.mainData.editEntityIndex]
				let has = false
				if(entity.is_relation_add){
					has = true
				}
				return has
			},
			hasZhuzhai(){
				let entity = this.mainData.entity_infos[this.mainData.editEntityIndex]
				let has = false
				if(entity.is_relation_add&&entity.entity_type_id==6){
					if(entity.basic_info.share_info.length==2){
						let p1= entity.basic_info.share_info[0].entity_name
						let p2= entity.basic_info.share_info[1].entity_name
						this.mainData.entity_infos.map(item=>{
							if(item.entity_name==p1||item.entity_name==p2){
								if(item.is_main==1){
									item.loan_purpose.map(res=>{
										if((res.purpose_id=='100004'||res.purpose_id==100004||
                      res.purpose_id==100013||res.purpose_id==100014||
                      res.purpose_id=='100013'||res.purpose_id=='100014')){
											has = true
										}
									})
								}
								let all_total=0
								item.basic_info.income_info.map(res=>{
									if(res.income_id!=''){
										all_total+=parseFloat(res.total)
									}
								})
								if(item.entity_name==p1){
									entity.basic_info.share_info[0].all_total=all_total
								}
								if(item.entity_name==p2){
									entity.basic_info.share_info[1].all_total=all_total
								}
							}
						})
					}				
				}
				return has
			},
			getAllTotalMoney(){
				let total = 0
				this.mainData.entity_infos.map(res=>{
					res.loan_purpose.map(loan=>{
						if(loan.purpose_id!=''&&loan.target_total!='')
						total+=parseFloat(loan.target_total)
					})
				})
				return this.formatMoney(total)
			},
			handleInputNumber(value){
				if(parseFloat(value)<0){
					return ''
				}
				return value.replace("e",'')
			},
			handleErrorClose(){
				this.showErrorDialog=false
			},
			//历史记录
			getRecordData(id,type) {
				const loading = this.$loading({
					lock: true,
					text: "Loading…",
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				getEstimateDetail({
					record_id: id,
					leave_id:type
				}).then(res => {
					this.mainData = JSON.parse(res.data.sub_datas)
					this.afterSaveData()
					loading.close()
					this.getTypeList()
				}).catch(err => {
					loading.close()
				})
			},

			
			//获取实体数量
			getMainLength() {
				let length = 0
				this.mainData.entity_infos.map(res => {
					if (res.is_main) {
						length++
					}
				})
				return length
			},
			//获取实体类型
			getTypeList() {
				//实体类型
				getEntityTypes({id:''}).then(res => {
					this.typeList = res.data//所有实体
					this.typeList2 = []//不含个人实体
					res.data.map(resItem => {
						if (resItem.id != 2) {
							this.typeList2.push(resItem)
						}
					})
				})
				//获取贷款用途
				this.loanTypeList2 = []
				getLoanPurposes({level:''}).then(res => {
					res.data.map(resItem => {
						this.loanTypeList2.push(resItem)
					})
				})
				this.loanTypeList = []
				getLoanPurposes({level:1}).then(res=>{
					this.loanTypeList = res.data
				}),
				this.pawnTypeList = []
				getPawnType().then(res=>{
					this.pawnTypeList = res.data
				})
				localStorage.setItem("tempData", JSON.stringify(this.mainData.entity_infos[this.mainData.editEntityIndex].basic_info))
			},
			getCancelList(item,ccc){
				console.log("1111",item)
				this.cancelList.push(item.entity_name)
				this.entityTreeList.map(parent =>{
					if(parent.entity_name==item.entity_name){//是父级
						if(parent.is_main!=1){
							parent.is_calculate = 0
							parent.childList.map(child=>{
								if(child.is_calculate==1){//如果子级勾选了，则查询其他父级是否有勾选
									let hasOther = false
									this.entityTreeList.map(parent2=>{
										if(parent2.entity_name!=parent.entity_name){
											parent2.childList.map(child2=>{
												if(child2.entity_name==child.entity_name){
													if(parent2.is_calculate==1){
														hasOther = true
													}
												}
											})
										}
									})
									if(!hasOther){
										if(child.is_main!=1){
											child.is_calculate=0
											this.entityTreeList.map(parent2=>{
												if(parent2.entity_name==child.entity_name){
													this.getCancelList(child)
												}
												parent2.childList.map(child2=>{
													if(child2.entity_name==child.entity_name){
														child2.is_calculate = 0
													}
												})
											})
											this.cancelList.push(child.entity_name)
										}
									}
								}
							})
						}
					}else{
						parent.childList.map(res=>{
							if(res.entity_name==item.entity_name){
								res.is_calculate = 0
								if(parent.is_main==1){
									res.is_calculate=1
									item.is_calculate=1
									this.$message({
										type: 'error',
										duration:this.showTime,
										message: 'Please uncheck the personal co branding option first'
									})
									return
								}
								
								//取消个人联名下的实体
								if(parent.entity_type_id==6&&!ccc){
									parent.childList.map(child=>{
										child.is_calculate = 0
										this.getCancelList(child,2)
									})
									this.getCancelList(parent,2)
								}
							}
						})
					}
				})
				
			},
			//选中框勾选事件缓存数据到本地缓存中
			async checkBoxChange(item, e, parentMain) { 
				if(item.is_main==1){
					if(item.is_calculate==0){
						item.is_calculate=1
						this.$message({
							type: 'error',
							duration: this.showTime,
							message: 'The loan subject cannot uncheck'
						})
						return
					}
				}
				if(item.is_calculate==0){
					let loading=this.$loading()
					item.is_calculate =1
					let tempTreeList= JSON.parse(JSON.stringify(this.entityTreeList))
					item.is_calculate =0
					
					this.cancelList = []
					this.getCancelList(item)
					
					
					this.cancelList=this.cancelList.filter((item, index, self) => self.indexOf(item) === index);
					
					this.mainData.loan_main = []
					this.mainData.entity_infos.map(res=>{
						if(res.is_main==1){
							let tempMain = {
								entity_type_id: res.entity_type_id,
								entity_name: res.entity_name,
								loan_purpose: res.loan_purpose
							}
							this.mainData.loan_main.push(tempMain)
						}
					})
					let params= {
						data:JSON.stringify(this.mainData),
						cancel_entity_names:this.cancelList.join(','),
						enlending:0
					}
					let result = await getLoanResult(params)
					loading.close()
					if(result.code==200){//可以取消
						
					}else{//不可取消
						this.$message({
							type: 'error',
							duration:this.showTime,
							message: result.msg
						})
						this.entityTreeList = tempTreeList
						item.is_calculate=1
					}
				}
				//子级勾选，父级联动勾选
				if(item.is_calculate==1){
					let isParent = false
					//勾选个人联名下的实体
					if(parentMain&&parentMain.entity_type_id==6){
						parentMain.childList.map(child=>{
							child.is_calculate=1
							this.checkBoxChange(child,1)
						})
					}
					this.entityTreeList.map(res=>{
						res.childList.map(cc=>{
							if(cc.entity_name==item.entity_name){
								if(res.entity_type_id==6){
									res.childList.map(child=>{
										child.is_calculate=1
									})
								}
							}
						})
					})
					
					
					if(!isParent){//如果不是父级
						this.entityTreeList.map(parent =>{
							parent.childList.map(child=>{
								if(child.entity_name==item.entity_name){
									if(parent.is_calculate==1){
										isParent = true
									}
								}
							})
						})
					}
					//无父级勾选 当前父级勾选
					if(!isParent){
						if(parentMain){
							parentMain.is_calculate = 1
							this.entityTreeList.map(parent1 =>{
								parent1.childList.map(child=>{
									if(child.entity_name==parentMain.entity_name){
										child.is_calculate=1
										this.checkBoxChange(child,1,parent1)
									}
								})
							})
						}else{
							this.entityTreeList.map(parent1 =>{
								parent1.childList.map(child=>{
									if(child.entity_name==item.entity_name){
										child.is_calculate=1
										this.checkBoxChange(child,1,parent1)
									}
								})
							})
						}
						
					}
				}

				this.entityTreeList.map(aa => {
					if (aa.entity_name == item.entity_name) {
						aa.is_calculate = item.is_calculate						
					}
					aa.childList.map(cc => {
						if (cc.entity_name == item.entity_name) {
							cc.is_calculate = item.is_calculate
						}
					})
				})
				//更新选中状态
				this.mainData.entity_infos.map(dd => {
					this.entityTreeList.map(aa => {
						if (aa.entity_name == dd.entity_name) {
							dd.is_calculate = aa.is_calculate						
						}
						aa.childList.map(cc => {
							if (cc.entity_name == dd.entity_name) {
								dd.is_calculate = cc.is_calculate
							}
						})
					})
				})
				//触发强绑定判断
				this.saveData()
				
			},
			//删除贷款主体
			delMainEntity(item) {
				if(item.is_main_change){
					this.$message({
						type: 'error',
						duration:this.showTime,
						message: this.QWER(`该主体存在负债转贷，请取消负债转贷后，再进行删除！`)
					})
				}else{
					this.$confirm(this.QWER('确定要删除吗?'), this.QWER('提示'), {
					          confirmButtonText: this.QWER('确定'),
					          cancelButtonText: this.QWER('取消'),
					          type: 'warning'
					        }).then(() => {
					         item.is_main = 0
					         item.basic_info.is_main = 0
					         this.afterSaveData()
					        }).catch(() => {
					                   
					        });
					
				}
				
			},
			//添加贷款项
			addPurposeItem(index) { //给将贷款实体转为贷款主体新增贷款类型
				let temp = {
					purpose_id: '',
					target_total: '',
					is_relend: 0,
					pawn_type:'',
					evaluate_value:'',
					building_cost:'',
					rent_total:'',
					presale_total:'',
          rental_income:''
				}
				this.mainData.entity_infos[index].loan_purpose.push(temp)
				this.saveData()
			},
			//删除贷款相
			delPurposeItem(index, index2) { //删除实体下的贷款类型
				this.$confirm("Deleting this loan purpose will also remove the corresponding New Purchase！", this.QWER('提示'), {
			          confirmButtonText: this.QWER('确定'),
			          cancelButtonText: this.QWER('取消'),
			          type: 'warning'
			        }).then(() => {
			         this.mainData.entity_infos[index].loan_purpose.splice(index2, 1)
			         this.saveData()
			        }).catch(() => {
			                   
			        });
			},
			showInputEmailDialog(){
				this.$refs.xinxi.save(2)
				setTimeout(()=>{
					this.submitErrorList = []
					this.mainData.loan_main = []
					this.mainData.entity_infos.map(res=>{
						let builddata = []
						
						if(res.is_main==1){
							res.loan_purpose.map(res111=>{
								if(res111.purpose_id==''&&res111.target_total!=''){
									this.submitErrorList.push(`Please select Loan Purpose [${res.entity_name}]`)
								}
							})
							let tempMain = {
								entity_type_id: res.entity_type_id,
								entity_name: res.entity_name,
								loan_purpose: res.loan_purpose
							}
							this.mainData.loan_main.push(tempMain)
							res.loan_purpose.map(loan => {
								if(loan.purpose_id==100007||loan.purpose_id==100008||loan.purpose_id==100009){
									
									builddata.push(loan)
								}
							})
						}else{
							res.new_pawn_info=[{
								pawn_type: "",
								income_total: "",
								index:-1,
								total: "",
								rental_income:"",
							}]
						}
						res.build_pawn_ino = builddata
						let hasItem = false
						let percent = 0
						if(res.entity_type_id==1||res.entity_type_id==5){
							res.basic_info.share_info.map(res11 => {
								if (res11.entity_type_id && res11.entity_name) {
									hasItem = true
									percent += parseFloat(res11.share_ratio?res11.share_ratio:0) 
								}
							})
						}
						percent = Math.round(percent*10000000)/10000000
						if (hasItem) {
							if (percent < 100) {
								this.submitErrorList.push(`[${res.entity_name}] `+this.QWER('股东持股比例为xxxxxx%,不足100%,请仔细核对！')+`, now is   ${percent}% `)
							}
							if (percent > 100) {
								this.submitErrorList.push(`[${res.entity_name}] `+this.QWER('股持股比例为xxxxxx%,超出100%,请重新填写！')+`, now is  ${percent}% `)
							}
						}

						let hasItem2 = false
						let percent2 = 0
						if(res.entity_type_id==4){
						console.log(res.basic_info.benef_info)
						  res.basic_info.benef_info.map(res11 => {
							if (res11.entity_type_id && res11.entity_name) {
							  hasItem2 = true
							  percent2 += parseFloat(res11.share_ratio?res11.share_ratio:0)
							}
							console.log(percent2)
						  })
						}
						percent2 = Math.round(percent2*10000000)/10000000
						if (hasItem2) {
						  let msg = res.entity_type_id==3?'The beneficiaries do not total to 100%':'The trustees do not total to 100%'
						  if (percent2 < 100) {
							this.submitErrorList.push(`[${res.entity_name}] ${msg}`)
						  }
						  if (percent2 > 100) {
							this.submitErrorList.push(`[${res.entity_name}] ${msg}`)
						  }
						}
					})
					this.submitErrorList = this.submitErrorList.filter((item, index, self) => self.indexOf(item) === index);
					
					
					if(!this.checkNewConstruction()){
						return
					}
					if(this.submitErrorList.length>0){
						this.showErrorDialog  = true
					}else{
						this.showConfirmDialog  = true
					}
				},150)
			},
      onClickEditResult(name){
        let temp ={
          entity_name:name
        }
        this.clickEditEntity(temp)
      },
			//新、编辑实体
			clickEditEntity(item2){
				this.mainData.entity_infos.map((res,index)=>{
					if(res.entity_name ==item2.entity_name){
						if(index!=this.mainData.editEntityIndex){
							this.$refs.xinxi.save(1)
							if(this.mainData.entity_infos.length>index){
								this.mainData.editEntityIndex = index
							}else{
								this.mainData.editEntityIndex = 0
							}
							
							localStorage.setItem("tempData", JSON.stringify(this.mainData.entity_infos[this.mainData.editEntityIndex].basic_info))

						
							setTimeout(()=>{
								this.$refs.xinxi.scrollTop()
							},100)
						}
					}
				})
			},
			//新、编辑实体
			clickEditEntity2(item2){
				this.mainData.entity_infos.map((res,index)=>{
					if(res.entity_name ==item2.target.entity_name){
						if(index!=this.mainData.editEntityIndex){
							this.$refs.xinxi.save(1)
							setTimeout(()=>{
								if(this.mainData.entity_infos.length>index){
									this.mainData.editEntityIndex = index
								}else{
									this.mainData.editEntityIndex = 0
								}
								localStorage.setItem("tempData", JSON.stringify(this.mainData.entity_infos[this.mainData.editEntityIndex].basic_info))
								this.$refs.xinxi.scrollToMy(item2.position)
								
								
							},100)
						}
					}
				})
			},
			//缓存数据到本地缓存中
			saveData() { 
				let temp1= JSON.stringify(this.mainData)
				localStorage.setItem("mainData", temp1)
			},
			//检测新建物业数据是否填写
			checkNewConstruction(){
				let builddatahasNoInput  =[]
				this.mainData.entity_infos.map(res=>{
					let has =false
					if(res.is_main==1){
						res.loan_purpose.map(loan => {
							if(loan.is_relend!=1){
								if(loan.purpose_id==100007){
									if(!loan.evaluate_value||!loan.building_cost){
										if(!has){
											builddatahasNoInput.push(res.entity_name)
											has = true
										}
											
									}
								}else if(loan.purpose_id==100008){
									if(!loan.evaluate_value||!loan.building_cost||!loan.rent_total){
										if(!has){
											builddatahasNoInput.push(res.entity_name)
											has = true
										}
									}
								}else if(loan.purpose_id==100009){
									if(!loan.evaluate_value||!loan.building_cost||!loan.presale_total){
										if(!has){
											builddatahasNoInput.push(res.entity_name)
											has = true
										}
									}
								}
							}
							
						})
						res.basic_info.debt_info.map(loan => {
							if(loan.is_relend==1){
								if(loan.purpose_id==100007){
									if(!loan.evaluate_value||!loan.building_cost){
										if(!has){
											builddatahasNoInput.push(res.entity_name)
											has = true
										}
									}
								}else if(loan.purpose_id==100008){
									if(!loan.evaluate_value||!loan.building_cost||!loan.rent_total){
										if(!has){
											builddatahasNoInput.push(res.entity_name)
											has = true
										}
									}
								}else if(loan.purpose_id==100009){
									if(!loan.evaluate_value||!loan.building_cost||!loan.presale_total){
										if(!has){
											builddatahasNoInput.push(res.entity_name)
											has = true
										}
									}
								}
							}
							
						})
					}
					
				})
				if(builddatahasNoInput.length>0){
					let msg = 'The data of “New Construction”is incomplete : <br/> -'+builddatahasNoInput.join("<br/>-")
					this.$alert(msg, this.QWER('提示'), {
					          confirmButtonText: this.QWER('确定'),
							  dangerouslyUseHTMLString: true,
					          callback: action => { }
					});
					this.showConfirmDialog = false
					return false
				}else{
					return true
				}
			},
			
			//保存按钮触发保存
			save(val) { //保存
				let loading=  this.$loading()
				let type = val.type
				let data = val.data
				if (type == 'xinxi') { //基础信息
					this.mainData.entity_infos[this.mainData.editEntityIndex].basic_info = data
					this.mainData.entity_infos[this.mainData.editEntityIndex].basic_info_has = val.has
					//更新实体名
					this.mainData.entity_infos[this.mainData.editEntityIndex].entity_name = data.entity_name
					//收入
					this.mainData.entity_infos[this.mainData.editEntityIndex].income_info = data.income_info
					//新增收入
					this.mainData.entity_infos[this.mainData.editEntityIndex].income_info_ebeta = data.income_info_ebeta
					//负债
					this.mainData.entity_infos[this.mainData.editEntityIndex].debt_info = data.debt_info
					//资产
					this.mainData.entity_infos[this.mainData.editEntityIndex].pawn_info = data.pawn_info
					let temploan_purpose = this.mainData.entity_infos[this.mainData.editEntityIndex].loan_purpose
			
					
					//新增资产
					this.mainData.entity_infos[this.mainData.editEntityIndex].new_pawn_info = JSON.parse(JSON.stringify(data.new_pawn_info))
					
					temploan_purpose.map(res=>{
						if(res.pawn_type&&res.is_relend!=1){
							this.mainData.entity_infos[this.mainData.editEntityIndex].new_pawn_info.push(res.pawn_type)
						}
					})
					
					this.mainData.entity_infos[this.mainData.editEntityIndex].hasFuzhai = data.hasFuzhai
					console.log(this.mainData.entity_infos[this.mainData.editEntityIndex])
					this.checkRealitonPeople()
					
					//请输入您家庭里18岁以下成员人数
					if((data.entity_type_id==2&&data.spouse_name=='')||data.entity_type_id==6){
						
					}else{
						data.people_count=''
					}
					this.mainData.entity_infos[this.mainData.editEntityIndex].people_count = data.people_count
					//支出
					this.mainData.entity_infos[this.mainData.editEntityIndex].expenditure = data.expenditure
					//原有贷款信息   更新转贷情况
					let tempsLoans = []
					let hasFuzhai = false
					data.debt_info.map(res => {
						if (res.is_relend == 1 && res.purpose_id != '') {
							hasFuzhai = true
							let temp = Object.assign({},res)
							temp.total2=''
							temp = {
								...temp,
								purpose_id: res.purpose_id,
								target_total: res.total,
							}
							tempsLoans.push(temp)
						}
						//396取消增额转贷 取消跟 转贷关联 res.is_relend == 1 && 
						if(res.total2 && res.purpose_id != ''){
							let temp2 = Object.assign({},res)
						
							temp2 = {
								...temp2,
								purpose_id: res.purpose_id,
								target_total: res.total2
							}
							temp2.is_relend =1
							tempsLoans.push(temp2)
						}
						console.log(tempsLoans)
					})
					let hasLoan = false
					
					this.mainData.entity_infos[this.mainData.editEntityIndex].loan_purpose.map(loan => {
						if (loan.is_relend == 0||loan.is_relend == '0') {
							hasLoan = true
							tempsLoans.push(loan)
						}
					})
					
					if (hasFuzhai) { //存在转贷的负债
						this.mainData.entity_infos[this.mainData.editEntityIndex].is_main= 1
	   					this.mainData.entity_infos[this.mainData.editEntityIndex].is_main_change= true
						this.mainData.entity_infos[this.mainData.editEntityIndex].basic_info.is_main= 1
					} else {
						this.mainData.entity_infos[this.mainData.editEntityIndex].is_main_change= 0
						if (!hasLoan) { //没有转贷负债以及没有贷款目标时
							this.mainData.entity_infos[this.mainData.editEntityIndex].is_main= 0
							this.mainData.entity_infos[this.mainData.editEntityIndex].basic_info.is_main= 0
						}
					}
					//转贷或 增额贷款>1
					if(tempsLoans.length>0){
						this.mainData.entity_infos[this.mainData.editEntityIndex].is_main= 1
						this.mainData.entity_infos[this.mainData.editEntityIndex].is_main_change= true
						this.mainData.entity_infos[this.mainData.editEntityIndex].basic_info.is_main= 1
					}
					this.mainData.entity_infos[this.mainData.editEntityIndex].loan_purpose = tempsLoans
					console.log("111",this.mainData.entity_infos[this.mainData.editEntityIndex].loan_purpose)
					let builddata = []
					if(this.mainData.entity_infos[this.mainData.editEntityIndex].is_main==1){
						this.mainData.entity_infos[this.mainData.editEntityIndex].loan_purpose.map(loan => {
							if(loan.purpose_id==100007||loan.purpose_id==100008||loan.purpose_id==100009){
								builddata.push(loan)
							}
						})
					}
					
					this.mainData.entity_infos[this.mainData.editEntityIndex].build_pawn_ino = builddata
					
					//资产
					this.mainData.entity_infos[this.mainData.editEntityIndex].pawn_info = data.pawn_info
				}
				if(!this.mainData.entity_infos[this.mainData.editEntityIndex].allHas)
					this.mainData.entity_infos[this.mainData.editEntityIndex].allHas = val.has
				
				
				
				this.addLianMingEntity()
				this.beforeSaveData()
				this.afterSaveData()
				
				
				loading.close()
				if(val.val==1||val.val==2){
					return
				}else{
					this.checkNewConstruction()
					setTimeout(()=>{
						this.$message({
							type: 'success',
							message: this.QWER('确认成功')
						})
					},300)
				}
			},
			//检查配偶是否已被填写
			checkRealitonPeople(){
				let entity = this.mainData.entity_infos[this.mainData.editEntityIndex]
				if(entity.basic_info.spouse_name==''){
					return
				}
				this.mainData.entity_infos.map(res=>{
					if(res.entity_type_id==2){
						if(entity.basic_info.spouse_name==res.entity_name&&entity.entity_name!=res.basic_info.spouse_name&&res.basic_info.spouse_name!=''){
							this.$message({
								type:'error',
								message:res.entity_name+this.QWER('已存在配偶')+res.basic_info.spouse_name,
							})
							entity.basic_info.spouse_name=''
						}
					}
				})
			},
			addLianMingEntity(){
				console.log(11)
				let entity= this.mainData.entity_infos[this.mainData.editEntityIndex]
				if(entity.entity_type_id==2&&entity.basic_info.is_strong!='1'&&entity.basic_info.spouse_name!=''){
					let has=false
					this.mainData.entity_infos.map(res=>{
						if(res.entity_type_id==6&&res.is_relation_add==entity.entity_name){
							res.entity_name = entity.entity_name+"&"+entity.basic_info.spouse_name
							res.basic_info.entity_name = entity.entity_name+"&"+entity.basic_info.spouse_name
							has = true
							res.basic_info.share_info[0]={
								"is_strong": 0,
								"is_strong2": 1,
								"entity_type_id": 2, //股东类型（个人、企业、家庭信托、单元信托）-传 id
								"entity_name": entity.entity_name, //股东 1/合伙人 1
								"share_ratio": '' //0.25
							}
							res.basic_info.share_info[1]={
								"is_strong": 0,
								"is_strong2": 1,
								"entity_type_id": 2, //股东类型（个人、企业、家庭信托、单元信托）-传 id
								"entity_name": entity.basic_info.spouse_name, //股东 1/合伙人 1
								"share_ratio": '' //0.25
							}
						}
					})
					if(!has){
						let temp = JSON.parse(JSON.stringify(this.entityInfoTemp))
						temp.entity_type_id = 6
						temp.is_relation_add = entity.entity_name
						temp.entity_name = entity.entity_name+"&"+entity.basic_info.spouse_name
						temp.is_main = 0//是否为主贷
						temp.is_calculate = 1//默认勾选
						temp.basic_info.entity_type_id = 6
						temp.basic_info.entity_name = entity.entity_name+"&"+entity.basic_info.spouse_name
						temp.basic_info.share_info=[]
						temp.basic_info.share_info.push({
							"is_strong": 0,
							"is_strong2": 1,
							"entity_type_id": 2, //股东类型（个人、企业、家庭信托、单元信托）-传 id
							"entity_name": entity.entity_name, //股东 1/合伙人 1
							"share_ratio": '' //0.25
						})
						
						temp.basic_info.share_info.push({
							"is_strong": 0,
							"is_strong2": 1,
							"entity_type_id": 2, //股东类型（个人、企业、家庭信托、单元信托）-传 id
							"entity_name": entity.basic_info.spouse_name, //股东 1/合伙人 1
							"share_ratio": '' //0.25
						})
						this.mainData.entity_infos.push(temp)
					}
				}
				if(entity.entity_type_id==2&&entity.basic_info.spouse_name==''){
					let has=false
					let tempindex= ''
					this.mainData.entity_infos.map((res,index)=>{
						if(res.entity_type_id==6&&res.is_relation_add==entity.entity_name){
							has = true
							tempindex = index
						}
					})
					if(has){
						this.mainData.entity_infos.splice(tempindex,1)
					}
					
				}
				
			},
			//保存之前跟新操作
			beforeSaveData(){
				//跟新所有企业实体的股东及董事持有的其他实体
				let tempEntitys = Object.assign([],this.mainData.entity_infos)
				tempEntitys.map(entity=>{
					if(entity.entity_type_id==1||entity.entity_type_id==5||entity.entity_type_id==3||entity.entity_type_id==4){
						//查询董事持有的其他实体
						let tempList = []
						
						if(entity.entity_type_id==1||entity.entity_type_id==5){
							entity.basic_info.director_info_list.map(dir=>{
								if(dir!='')
									tempList = tempList.concat(this.getOtherEntityList(entity.entity_name,dir,2))
							})
							//查询股东持有的实体
							entity.basic_info.share_info.map(share=>{
								if(share.entity_name!=''&&share.entity_type_id!='')
									tempList = tempList.concat(this.getOtherEntityList(entity.entity_name,share.entity_name,share.entity_type_id))
							})
						}
						if(entity.entity_type_id==3||entity.entity_type_id==4){
							//查询股东持有的实体
							entity.basic_info.benef_info.map(share=>{
								if(share.entity_name!=''&&share.entity_type_id!='')
									tempList = tempList.concat(this.getOtherEntityList(entity.entity_name,share.entity_name,share.entity_type_id))
							})
						}
						let result = []
						tempList.map(temp=>{
							let find =false
							entity.basic_info.share_other_info.map(ss=>{
								if(ss.is_strong!='1'&& temp.entity_names==ss.entity_names&&
								temp.entity_name==ss.entity_name&&
								temp.entity_type_id==ss.entity_type_id&&ss.entity_name!=entity.entity_name){
									find=true
								}
							})
							result.map(ss=>{
								if(temp.entity_names==ss.entity_names&&
								temp.entity_name==ss.entity_name&&
								temp.entity_type_id==ss.entity_type_id&&ss.entity_name!=entity.entity_name){
									find=true
								}
							})
							if(!find){
								if(!temp.share_ratio){
									temp.share_ratio=0
								}
								// if(temp.entity_type_id==3||temp.entity_type_id==4){
								// 	temp.share_ratio=100
								// }
								result.push(temp)
							}
						})
						entity.basic_info.share_other_info.map(ss=>{
							if(ss.is_strong!='1'){
								result.push(ss)
							}
						})
						entity.basic_info.share_other_info = JSON.parse(JSON.stringify(result))
					}
				})
				this.mainData.entity_infos = tempEntitys
			},
			addChooseInputList(data){
				let has =false
				let name =data.entity_name
				let type = data.entity_type_id
				if(name!=''&&type!=''){
					this.inputChooseList.map(res=>{
						if(res.value==name){
							has = true
						}
					})
					if(!has){
						this.inputChooseList.push({
							entity_type_id:type,
							value:name
						})
					}
				}
				
			},
			getOtherEntityList(parent,name,type){
				if(name==''||type==''){
					return []
				}
				let tempList = []
				this.mainData.entity_infos.map(entity=>{
					if(entity.entity_type_id==1||entity.entity_name==5){//企业合伙企业
						if(parent!=entity.entity_name){
							//查询股东持有的实体
							entity.basic_info.share_info.map(share=>{
								if(share.entity_name==name&&share.entity_type_id==type&&share.is_strong!='1'){
									let temps = Object.assign({},share)
									temps.entity_names = name
									temps.entity_name = entity.entity_name
									temps.entity_type_id = entity.entity_type_id
									temps.is_strong = '1'
									temps.position = 51
									let ttt = {
										entity_type_id: entity.entity_type_id,
										entity_name:entity.entity_name
									}
									temps.target = ttt
									tempList.push(temps)
								}
									
							})
						}
					}
					if(entity.entity_type_id==3||entity.entity_name==4){//信托
						if(parent!=entity.entity_name){
							//查询受托人 受益人
							entity.basic_info.benef_info.map(share=>{
								if(share.entity_name==name&&share.entity_type_id==type&&share.is_strong!='1'){
									let temps = Object.assign({},share)
									temps.entity_names = name
									temps.entity_name = entity.entity_name
									temps.entity_type_id = entity.entity_type_id
									temps.is_strong = '1'
									temps.position = 41
									let ttt = {
										entity_type_id: entity.entity_type_id,
										entity_name:entity.entity_name
									}
									temps.target = ttt
									tempList.push(temps)
								}
									
							})
						}
					}
					//查询董事持有的其他实体
					if(entity.entity_type_id==type&&entity.entity_name==name){
						entity.basic_info.entity_other_info.map(other=>{
							if(other.entity_name!=''&&other.entity_type_id!=''&&other.entity_name!=parent&&other.is_strong!='1'){
								let temps = Object.assign({},other)
								temps.entity_names = name
								temps.is_strong = '1'
								temps.position = 1
								let ttt = {
									entity_type_id: entity.entity_type_id,
									entity_name:entity.entity_name
								}
								temps.target = ttt
								tempList.push(temps)
							}
						})
					}
					entity.basic_info.share_other_info.map(share=>{
						if(share.entity_names==name&&share.is_strong!='1'&&share.entity_name!=parent&&entity.entity_name!=parent){
							let temps = Object.assign({},share)
							temps.is_strong = '1'
							temps.position = 1
							let ttt = {
								entity_type_id: entity.entity_type_id,
								entity_name:entity.entity_name
							}
							temps.target = ttt
							tempList.push(temps)
						}
					})
					
				})
				return tempList
			},
			
			//跟新编辑以后执行一系列操作
			afterSaveData(){
				//新、根据当前编辑实体 添加or更新实体列表
				this.saveActiveEntity(this.mainData.entity_infos[this.mainData.editEntityIndex])
				
				//新、更新实体附带强绑定信息
				this.updateEntityShareInfo()
				
				//新、更新树状图
				this.updateTreeListInfo()
			
				// //新、更新实体附带强绑定信息
				this.updateEntityShareInfo()
				//新、更新树状图
				this.updateTreeListInfo()
				
				//检测夫妻联名实体是否合法
				this.checkRealtionRight()
				//根据树状图 判断实体是否该被删除 、将实体列表选中状态、填写状态更新至树状图
				this.updateEntityInfoToTree()
				
				this.inputChooseList = []
				this.mainData.entity_infos.map(res=>{
					this.addChooseInputList(res)
					this.addChooseInputList({entity_type_id:2,entity_name:res.basic_info.spouse_name})
					res.basic_info.director_info_list.map(item =>{
						this.addChooseInputList({entity_type_id:2,entity_name:item.name})
					})
					res.basic_info.benef_info.map(item =>{
						this.addChooseInputList(item)
					})
					res.basic_info.share_info.map(item =>{
						this.addChooseInputList(item)
					})
					res.basic_info.share_other_info.map(item =>{
						this.addChooseInputList(item)
					})
					res.basic_info.entity_other_info.map(item =>{
						this.addChooseInputList(item)
					})
					this.addChooseInputList(res.basic_info.trustee_info)
				})
				
				//保存到缓存
				this.saveData()
			},
			//检测夫妻联名是否合法
			checkRealtionRight(){
				let templist = JSON.parse(JSON.stringify(this.entityTreeList))
				console.log(templist)
				let result=[]
				templist.map(res=>{
					let has = false
					
					if(res.entity_type_id==6){
						let reslation = ''
						this.mainData.entity_infos.map(aa=>{
							if(aa.entity_name==res.entity_name&&aa.is_relation_add){
								if(aa.is_family==1){
									has = true
								}else{
									reslation = aa.is_relation_add
								}
							}
						})
						if(reslation){
							if(res.childList.length>1){
								let entity = null
								res.childList.map(child=>{
									if(child.entity_name==reslation){
										entity = child
									}
								})
								if(entity){
									if(entity.is_main==1){
										has = true
									}
									this.mainData.entity_infos.map((item,index)=>{
										if(item.entity_name==entity.entity_name&&index==0){
											has = true
										}
									})
									this.entityTreeList.map(res11=>{
										if(res11.entity_name!=res.entity_name){
											res11.childList.map(child=>{
												if(child.entity_name==entity.entity_name){
													has = true
												}
											})
										}
									})
								}
								
							}
						}else{
							has = true
						}
						
						if(res.is_main==1){
							has = true
						}
					}else{
						has = true
					}
					if(has){
						result.push(res)
					}
				})
				this.entityTreeList = result
			},
			//新、添加实体
			addEntityInfoItem(item,poi) { 
				if(item.entity_name==''||item.entity_type_id==''||(item.is_strong&&item.is_strong=='1')){
					return
				}
				let hasItem = false
				this.mainData.entity_infos.map((res, index) => {
					if (res.entity_name == item.entity_name) {
						res.entity_type_id = item.entity_type_id
						res.basic_info.entity_type_id = item.entity_type_id
						hasItem = true
					}
				})
				if (!hasItem) {//实体列表中没有该实体，则新增该实体
					let temp = JSON.parse(JSON.stringify(this.entityInfoTemp))
					temp.entity_type_id = item.entity_type_id
					temp.entity_name = item.entity_name
					temp.is_main = item.is_main?item.is_main:0//是否为主贷
					temp.is_calculate = 1//默认勾选
					temp.basic_info.entity_type_id = item.entity_type_id
					temp.basic_info.entity_name = item.entity_name
					
					this.mainData.entity_infos.push(temp)
				}
			},
			//新、保存当前编辑的实体 
			saveActiveEntity(res){
				if (res.entity_type_id == 6) { //企业  合伙企业
					// 股东实体
					res.basic_info.share_info.map((resInfo, ii) => {
						this.addEntityInfoItem(resInfo)
					})
				}
				if (res.entity_type_id == 1 || res.entity_type_id == 5) { //企业  合伙企业 个人联名
					// 股东实体
					if(res.basic_info.share_info_true!==false){
						res.basic_info.share_info.map((resInfo, ii) => {
							this.addEntityInfoItem(resInfo)
						})
					}
					
					let templist = []
					
					res.basic_info.share_other_info.map(resInfo => {
						if(resInfo.entity_type_id!=''&&resInfo.entity_name!=''){
							let find = false
							templist.map(tempInfo=>{
								if(tempInfo.entity_name==resInfo.entity_name){
									find = true
									tempInfo.entity_names_list.push(resInfo.entity_names)
									tempInfo.share_ratio=parseFloat(tempInfo.share_ratio?tempInfo.share_ratio:0) + parseFloat(resInfo.share_ratio?resInfo.share_ratio:0)
								}
							})
							if(!find){
								resInfo.entity_names_list = []
								resInfo.entity_names_list.push(resInfo.entity_names)
								templist.push(JSON.parse(JSON.stringify(resInfo)))
							}
						}
					})
					//董事及董事持有的其他实体
					templist.map(resInfo => {
						if (parseFloat(resInfo.share_ratio)>= this.standNum) {
							//判断 股东或董事 是否只有董事
							let hasGudong = false
							resInfo.entity_names_list.map(listItem => {
								//看是否有股东信息
								res.basic_info.share_info.map(ee => {
									if (ee.entity_name == listItem) {
										hasGudong = true
									}
								})
							})
							if(hasGudong){
								this.addEntityInfoItem(resInfo)
								resInfo.entity_names_list.map(listItem => {
									let temp = {
										entity_type_id: 2,
										entity_name: listItem
									}
									//看是否有股东信息
									res.basic_info.share_info.map(ee => {
										if (ee.entity_name == listItem) {
											temp.entity_type_id = ee.entity_type_id
										}
									})
									this.addEntityInfoItem(temp)
								})
							}
						}
					})
				}
				if (res.entity_type_id == 2) { //个人
					//配偶添加至子实体
					let temp = {
						entity_type_id: 2,
						entity_name: res.basic_info.spouse_name
					}
					this.addEntityInfoItem(temp)
				}
				if (res.entity_type_id == 3 || res.entity_type_id == 4) { //家庭信托 ||单元信托
					//受益人信息
					res.basic_info.benef_info.map(resInfo => {
						this.addEntityInfoItem(resInfo)
					})
					//受益人持有的其他实体 添加至主实体
					let templist1 = []
					res.basic_info.share_other_info.map(resInfo => {
						if(resInfo.entity_type_id!=''&&resInfo.entity_name!=''){
							let find = false
							templist1.map(tempInfo=>{
								if(tempInfo.entity_name==resInfo.entity_name){
									find = true
									tempInfo.entity_names_list.push(resInfo.entity_names)
									tempInfo.share_ratio=parseFloat(tempInfo.share_ratio?tempInfo.share_ratio:0)+parseFloat(resInfo.share_ratio?resInfo.share_ratio:0)
								}
							})
							if(!find){
								resInfo.entity_names_list = []
								resInfo.entity_names_list.push(resInfo.entity_names)
								templist1.push(JSON.parse(JSON.stringify(resInfo)))
							}
						}
					})
					templist1.map(resInfo => {
						if (parseFloat(resInfo.share_ratio)>= this.standNum) {
							this.addEntityInfoItem(resInfo)
							resInfo.entity_names_list.map(listItem => {
								let temp = {
									is_strong: '1',
									entity_type_id: '',
									entity_name: listItem
								}
								res.basic_info.benef_info.map(benef => {
									if (benef.entity_name == listItem) {
										temp.entity_type_id = benef.entity_type_id
									}
								})
								this.addEntityInfoItem(temp)
							})
						}
					})
					//受托人
					this.addEntityInfoItem(res.basic_info.trustee_info,1)
					
				}
				//判断该实体持有其他实体持股大于25的直接加入新实体
				res.basic_info.entity_other_info.map((resInfo, ii) => {
					if (resInfo.share_ratio >= this.standNum) {
						this.addEntityInfoItem(resInfo)
					}
				})
			},
			//新、更新实体附带强绑定信息
			updateEntityShareInfo(){
				//寻找其他实体
				this.mainData.entity_infos.map((aaa,index) => {
					aaa.basic_info.xintuolist=[]//信托制空
					
					let share_info = {}//股东信息
					let share_info_list = []//股东信息
					let entity_other_info = {}//持有的其他实体
					let entity_other_info_list = []//持有的其他实体
					let share_other_info = {}//持股东/董事/合伙人/受益人持有的其他实体信息
					let share_other_info_list = []//股东/董事/合伙人/受益人持有的其他实体信息
				
					this.mainData.entity_infos.map(res => {
						//判断res持有的其他实体中包含aaa,则res变为aaa 的股东
						let targetRes= {
							entity_type_id:res.entity_type_id,
							entity_name:res.entity_name
						}
						res.basic_info.entity_other_info.map((resInfo, ii) => {
							if(resInfo.entity_name==aaa.entity_name&&resInfo.entity_type_id==aaa.entity_type_id&&resInfo.is_strong!='1'){
								!share_info[res.entity_name]?share_info[res.entity_name]={
									is_strong: '1',
									target:targetRes,
									position:1,
									entity_type_id: res.entity_type_id,
									entity_name: res.entity_name,
									share_ratio: resInfo.share_ratio
								}:''	
							}
						})
						if (res.entity_type_id == 6) { //个人联名
							//res某股东中包含aaa实体,则res转为aaa持有的其他实体
							res.basic_info.share_info.map((resInfo, ii) => {
								if(resInfo.entity_name==aaa.entity_name&&resInfo.entity_type_id==aaa.basic_info.entity_type_id&&resInfo.is_strong!='1'){
									!entity_other_info[res.entity_name]?entity_other_info[res.entity_name]={
										is_strong: '1',
										target:targetRes,
										position:61,
										entity_type_id: res.entity_type_id,
										entity_name: res.entity_name,
										share_ratio: 100
									}:''
								}
							})
						}
						if (res.entity_type_id == 1 || res.entity_type_id == 5) { //企业  合伙企业
							//res某股东中包含aaa实体,则res转为aaa持有的其他实体
							if(res.basic_info.share_info_true!=false){
								res.basic_info.share_info.map((resInfo, ii) => {
									if(resInfo.entity_name==aaa.entity_name&&resInfo.entity_type_id==aaa.basic_info.entity_type_id&&resInfo.is_strong!='1'){
										!entity_other_info[res.entity_name]?entity_other_info[res.entity_name]={
											is_strong: '1',
											target:targetRes,
											position:51,
											entity_type_id: res.entity_type_id,
											entity_name: res.entity_name,
											share_ratio: resInfo.share_ratio
										}:''
									}
								})
							}
							
							//res董事和股东持有的其他实体包含aaa,则res的董事和股东变为aaa的股东
							res.basic_info.share_other_info.map(resInfo => {
								if(resInfo.entity_name==aaa.entity_name&&resInfo.entity_type_id==aaa.entity_type_id){
									let temp3 = {
										is_strong: '1',
										target:targetRes,
										entity_type_id: 2,
										position:52,
										entity_name: resInfo.entity_names,
										share_ratio: resInfo.share_ratio
									}
									res.basic_info.share_info.map(ee => {
										if (ee.entity_name == resInfo.entity_names) {
											temp3.entity_type_id = ee.entity_type_id
										}
									})
									!share_info[temp3.entity_name]?share_info[temp3.entity_name]=temp3:''
								}
								if(resInfo.entity_names==aaa.entity_name&&resInfo.is_strong!='1'){
									!entity_other_info[resInfo.entity_name]?entity_other_info[resInfo.entity_name]={
										is_strong: '1',
										target:targetRes,
										position:52,
										entity_type_id: resInfo.entity_type_id,
										entity_name: resInfo.entity_name,
										share_ratio: resInfo.share_ratio
									}:''
								}
							})
						}
						if (res.entity_type_id == 2) { //个人
							if(res.basic_info.spouse_name == aaa.entity_name &&aaa.entity_type_id==2&&res.basic_info.is_strong!='1'){
								aaa.basic_info.spouse_name = res.entity_name
								aaa.basic_info.is_strong = '1'
							}
						}
						if(aaa.basic_info.spouse_name==res.entity_name&&res.basic_info.spouse_name!=aaa.entity_name&&aaa.basic_info.is_strong=='1'){
							aaa.basic_info.spouse_name=''
							aaa.basic_info.is_strong='0'
						}
						if (res.entity_type_id == 3 || res.entity_type_id == 4) { //家庭信托 ||单元信托
							if(res.basic_info.trustee_info.entity_type_id==aaa.entity_type_id&&res.basic_info.trustee_info.entity_name==aaa.entity_name){
								aaa.basic_info.xintuolist.push(res.entity_name)
							}
							//res的受益人中包含aaa,则aaa持有的其他的实体中默认包含res
							res.basic_info.benef_info.map(resInfo => {
								if(resInfo.entity_name==aaa.entity_name&&resInfo.entity_type_id==aaa.entity_type_id&&resInfo.is_strong!='1'){
									!entity_other_info[res.entity_name]?entity_other_info[res.entity_name]= {
											is_strong: '1',
											target:targetRes,
											position:41,
											entity_type_id: res.entity_type_id,
											entity_name: res.entity_name,
											share_ratio:res.entity_type_id==4?resInfo.share_ratio:100
										}:''
								}
							})
							//受益人持有的其他实体 添加至主实体
							res.basic_info.share_other_info.map(resInfo => {
								if(resInfo.entity_name==aaa.entity_name&&resInfo.entity_type_id==aaa.entity_type_id&&resInfo.is_strong!='1'){
									let temp3 = {
										is_strong: '1',
										target:targetRes,
										entity_type_id: 2,
										position:42,
										entity_name: resInfo.entity_names,
										share_ratio: resInfo.share_ratio
									}
									res.basic_info.benef_info.map(ee => {
										if (ee.entity_name == resInfo.entity_names) {
											temp3.entity_type_id = ee.entity_type_id
										}
									})
									!share_info[temp3.entity_name]?share_info[temp3.entity_name]=temp3:''
								}
								if(resInfo.entity_names==aaa.entity_name&&resInfo.is_strong!='1'){
									!entity_other_info[resInfo.entity_name]?entity_other_info[resInfo.entity_name]={
										is_strong: '1',
										target:targetRes,
										position:42,
										entity_type_id: resInfo.entity_type_id,
										entity_name: resInfo.entity_name,
										share_ratio: resInfo.share_ratio
									}:''
								}
							})
						}
					})
					if (aaa.entity_type_id == 1 || aaa.entity_type_id == 5|| aaa.entity_type_id == 6) { //企业  合伙企业
						aaa.basic_info.share_info.map(res1=>{
							if(res1.is_strong!='1')
								share_info[res1.entity_name] = res1
						})
					}
					if (aaa.entity_type_id == 3 || aaa.entity_type_id == 4) { //企业  合伙企业
						aaa.basic_info.benef_info.map(res1=>{
							if(res1.is_strong!='1')
								share_info[res1.entity_name] = res1
						})
					}
					aaa.basic_info.entity_other_info.map(res1=>{
						if(res1.is_strong!='1')
							entity_other_info[res1.entity_name] = res1
					})
					aaa.basic_info.share_other_info.map(res1=>{
						if(res1.is_strong!='1')
							share_other_info[res1.entity_name] = res1
					})
					for (const key in share_info) {
						share_info_list.push(share_info[key])
					}
					for (const key in entity_other_info) {
						entity_other_info_list.push(entity_other_info[key])
					}

					if (aaa.entity_type_id == 1 || aaa.entity_type_id == 5|| aaa.entity_type_id == 6) { //企业  合伙企业
						this.mainData.entity_infos[index].basic_info.share_info = share_info_list
					}
					if (aaa.entity_type_id == 3 || aaa.entity_type_id == 4) { //企业  合伙企业
						this.mainData.entity_infos[index].basic_info.benef_info = share_info_list
					}
					
					this.mainData.entity_infos[index].basic_info.entity_other_info = entity_other_info_list
				})
			},
			//新、更新左侧树状图
			updateTreeListInfo(){
				//树状图
				this.entityTreeList = []
				//关系图连线
				this.relationList = []
				this.mainData.entity_infos.map((res,index)=>{
					//添加至一级实体
					if(index==0){
						this.addTreeParent(res,1)
					}
					if(res.entity_type_id==6&&res.is_relation_add){
						this.addTreeParent(res,2)
					}
					if (res.entity_type_id == 6) { //个人联名
						// 股东实体
						res.basic_info.share_info.map((resInfo, ii) => {
							if(resInfo.entity_type_id==2){
								this.addTreeChildren(res,resInfo) 
							}
						})
					}
					if (res.entity_type_id == 1 || res.entity_type_id == 5) { //企业  合伙企业
						// 股东实体
						if(res.basic_info.share_info_true!==false){
							res.basic_info.share_info.map((resInfo, ii) => {
								if(resInfo.entity_type_id==2){
									this.addTreeChildren(res,resInfo) 
								}else{
									this.addTreeParent(resInfo,3)
									this.addTreeChildren(res,resInfo) 
								}
							})
						}
						
						
						//董事及董事持有的其他实体
						let templist1 = []
						
						res.basic_info.share_other_info.map(resInfo => {
							if(resInfo.entity_type_id!=''&&resInfo.entity_name!=''&&resInfo.entity_names!=''&&resInfo.is_strong!=1){
								let find = false
								templist1.map(tempInfo=>{
									if(tempInfo.entity_name==resInfo.entity_name){
										find = true
										tempInfo.entity_names_list.push(resInfo.entity_names)
										tempInfo.share_ratio=parseFloat(tempInfo.share_ratio?tempInfo.share_ratio:0) + parseFloat(resInfo.share_ratio?resInfo.share_ratio:0)
									}
								})
								if(!find){
									resInfo.entity_names_list = []
									resInfo.entity_names_list.push(resInfo.entity_names)
									templist1.push(JSON.parse(JSON.stringify(resInfo)))
								}
							}
						})
						templist1.map(resInfo => {
							let isRight = false
							if (parseFloat(resInfo.share_ratio)>= this.standNum) {
								//判断 股东或董事 是否只有董事
								let hasGudong = false
								resInfo.entity_names_list.map(listItem => {
									//看是否有股东信息
									res.basic_info.share_info.map(ee => {
										if (ee.entity_name == listItem) {
											hasGudong = true
										}
									})
								})
								//是股东和董事共同持有的改 其他实体 则进行股东和董事添加至子实体
								if(hasGudong){
									isRight = true
									//其他实体添加至一级实体
									this.addTreeParent(resInfo,4)
									resInfo.is_strong=0
									this.addEntityInfoItem(resInfo)
									resInfo.entity_names_list.map(listItem => {
										let temp = {
											entity_type_id: 2,
											entity_name: listItem
										}
										//看是否有股东信息
										res.basic_info.share_info.map(ee => {
											if (ee.entity_name == listItem) {
												temp.entity_type_id = ee.entity_type_id
											}
										})
										this.addEntityInfoItem(temp)
										if(temp.entity_type_id==2){
											this.addTreeChildren(res,temp) 
										}else{
											this.addTreeParent(temp,5)
											this.addTreeChildren(res,temp) 
										}
										
									})
								}
							}
							if(!isRight){
								//非法实体，进行删除实体
								this.deleteErrEntity(resInfo)
							}
						})
					}
					if (res.entity_type_id == 2) { //个人
						//配偶添加至子实体
						let temp = {
							entity_type_id: 2,
							entity_name: res.basic_info.spouse_name
						}
						//添加至一级实体
						this.addTreeParent(temp,6)
					}
					if (res.entity_type_id == 3 || res.entity_type_id == 4) { //家庭信托 ||单元信托
						//受益人信息添加至二级实体
						res.basic_info.benef_info.map(resInfo => {
							if(resInfo.entity_type_id==2){
								this.addTreeChildren(res,resInfo) 
							}else{
								this.addTreeParent(resInfo,7)
								this.addTreeChildren(res,resInfo) 
							}
						})
						//受益人持有的其他实体 添加至主实体
						let templist2 = []
						res.basic_info.share_other_info.map(resInfo => {
							if(resInfo.entity_type_id!=''&&resInfo.entity_name!=''){
								let find = false
								templist2.map(tempInfo=>{
									if(tempInfo.entity_name==resInfo.entity_name){
										find = true
										tempInfo.entity_names_list.push(resInfo.entity_names)
										tempInfo.share_ratio=parseFloat(tempInfo.share_ratio?tempInfo.share_ratio:0)+parseFloat(resInfo.share_ratio?resInfo.share_ratio:0)
									}
								})
								if(!find){
									resInfo.entity_names_list = []
									resInfo.entity_names_list.push(resInfo.entity_names)
									templist2.push(JSON.parse(JSON.stringify(resInfo)))
								}
							}
						})
            let isRight = false
						templist2.map(resInfo => {
							if (parseFloat(resInfo.share_ratio)>= this.standNum) {
                isRight  = true
								this.addTreeParent(resInfo,8)
								resInfo.entity_names_list.map(listItem => {
									let temp = {
										is_strong: '1',
										entity_type_id: '',
										entity_name: listItem
									}
									res.basic_info.benef_info.map(benef => {
										if (benef.entity_name == listItem) {
											temp.entity_type_id = benef.entity_type_id
										}
									})
									if(temp.entity_type_id==2){
										this.addTreeChildren(res,temp) 
									}else{
										this.addTreeParent(temp,9)
										this.addTreeChildren(res,temp) 
									}
								})
							}
              if(!isRight){
                //非法实体，进行删除实体
                this.deleteErrEntity(resInfo)
              }
						})
						if(res.basic_info.trustee_info.entity_type_id==2){
							this.addTreeChildren(res,res.basic_info.trustee_info) 
						}else{
							this.addTreeParent(res.basic_info.trustee_info,10)
							this.addTreeChildren(res,res.basic_info.trustee_info) 
						}
					}
					//判断该实体持有其他实体持股大于25的直接加入新实体
					res.basic_info.entity_other_info.map((resInfo, ii) => {
						if (resInfo.share_ratio >= this.standNum) {
							this.addTreeParent(resInfo,11)
						}
					})
				})
			},
			
			//删除非法实体
			deleteErrEntity(item){
				let tempEntityInfos = Object.assign([],this.mainData.entity_infos)
				let result =[]
				tempEntityInfos.map(res111=>{
					let isRight = true
					if(item.entity_name==res111.entity_name){
						isRight=false
						tempEntityInfos.map(res=>{
							if (res.entity_type_id == 6) { //企业  合伙企业
								// 股东实体
								res.basic_info.share_info.map((resInfo, ii) => {
									if(resInfo.entity_name==item.entity_name){
										isRight = true
									}
								})
							}
							if (res.entity_type_id == 1 || res.entity_type_id == 5) { //企业  合伙企业
								// 股东实体
								if(res.basic_info.share_info_true!==false){
									res.basic_info.share_info.map((resInfo, ii) => {
										if(resInfo.entity_name==item.entity_name){
											isRight = true
										}
									})
								}
								
								let templist = []
								res.basic_info.share_other_info.map(resInfo => {
									if(resInfo.entity_type_id!=''&&resInfo.entity_name!=''){
										let find = false
										templist.map(tempInfo=>{
											if(tempInfo.entity_name==resInfo.entity_name){
												find = true
												tempInfo.entity_names_list.push(resInfo.entity_names)
												tempInfo.share_ratio=parseFloat(tempInfo.share_ratio?tempInfo.share_ratio:0) + parseFloat(resInfo.share_ratio?resInfo.share_ratio:0)
											}
										})
										if(!find){
											resInfo.entity_names_list = []
											resInfo.entity_names_list.push(resInfo.entity_names)
											templist.push(JSON.parse(JSON.stringify(resInfo)))
										}
									}
								})
								//董事及董事持有的其他实体
								templist.map(resInfo => {
									if (parseFloat(resInfo.share_ratio)>= this.standNum) {
										//判断 股东或董事 是否只有董事
										let hasGudong = false
										resInfo.entity_names_list.map(listItem => {
											//看是否有股东信息
											res.basic_info.share_info.map(ee => {
												if (ee.entity_name == listItem) {
													hasGudong = true
												}
											})
										})
										if(hasGudong){
											if(resInfo.entity_name==item.entity_name){
												isRight = true
											}
											resInfo.entity_names_list.map(listItem => {
												let temp = {
													entity_type_id: 2,
													entity_name: listItem
												}
												//看是否有股东信息
												res.basic_info.share_info.map(ee => {
													if (ee.entity_name == listItem) {
														temp.entity_type_id = ee.entity_type_id
													}
												})
												if(temp.entity_name==item.entity_name){
													isRight = true
												}
											})
										}
									}
								})
							}
							
							if (res.entity_type_id == 3 || res.entity_type_id == 4) { //家庭信托 ||单元信托
								//受益人信息
								res.basic_info.benef_info.map(resInfo => {
									if(resInfo.entity_name==item.entity_name){
										isRight = true
									}
								})
								//受益人持有的其他实体 添加至主实体
								let templist1 = []
								res.basic_info.share_other_info.map(resInfo => {
									if(resInfo.entity_type_id!=''&&resInfo.entity_name!=''){
										let find = false
										templist1.map(tempInfo=>{
											if(tempInfo.entity_name==resInfo.entity_name){
												find = true
												tempInfo.entity_names_list.push(resInfo.entity_names)
												tempInfo.share_ratio=parseFloat(tempInfo.share_ratio?tempInfo.share_ratio:0)+parseFloat(resInfo.share_ratio?resInfo.share_ratio:0)
											}
										})
										if(!find){
											resInfo.entity_names_list = []
											resInfo.entity_names_list.push(resInfo.entity_names)
											templist1.push(JSON.parse(JSON.stringify(resInfo)))
										}
									}
								})
								templist1.map(resInfo => {
									if (parseFloat(resInfo.share_ratio)>= this.standNum) {
										if(resInfo.entity_name==item.entity_name){
											isRight = true
										}
										resInfo.entity_names_list.map(listItem => {
											let temp = {
												is_strong: '1',
												entity_type_id: '',
												entity_name: listItem
											}
											res.basic_info.benef_info.map(benef => {
												if (benef.entity_name == listItem) {
													temp.entity_type_id = benef.entity_type_id
												}
											})
											if(temp.entity_name==item.entity_name){
												isRight = true
											}
										})
									}
								})
								if (res.basic_info.trustee_info.entity_type_id == 1) { //受托人为企业 考虑改企业下的子实体问题
									// this.addEntityInfoItem(res.basic_info.trustee_info)
									if(res.basic_info.trustee_info.entity_name==item.entity_name){
										isRight = true
									}
								}
							}
							//判断该实体持有其他实体持股大于25的直接加入新实体
							res.basic_info.entity_other_info.map((resInfo, ii) => {
								if (resInfo.share_ratio >= this.standNum) {
									if(resInfo.entity_name==item.entity_name){
										isRight = true
									}
								}
							})
						})
					}
					if(isRight){
						result.push(res111)
					}
				})
				this.mainData.entity_infos = result
			},
			//根据实体列表跟新树状图 以及根据树状图删除多余实体列表
			updateEntityInfoToTree(){
				let tempEntityInfos = Object.assign([],this.mainData.entity_infos)
				let result =[]
				tempEntityInfos.map(res=>{
					let hasItem = false
					this.entityTreeList.map(parent=>{
						if(parent.entity_name == res.entity_name){
							hasItem = true
							parent.data = ''
							parent.allHas = res.allHas
							parent.is_main = res.is_main
							if(res.is_main==1){
								parent.is_calculate =1
							}else{
								parent.is_calculate = res.is_calculate
							}
							
							parent.hasFuzhai = res.hasFuzhai//是否有负债无收入
							if(res.hasFuzhai){
								parent.is_calculate = 1
							}
						}
						
						parent.childList.map(child=>{
							if(child.entity_name==res.entity_name){
								hasItem = true
								child.data = ''
								child.allHas = res.allHas
								child.is_main = res.is_main
								if(res.is_main==1){
									child.is_calculate =1
								}else{
									child.is_calculate = res.is_calculate
								}
								child.hasFuzhai = res.hasFuzhai//是否有负债无收入
								if(res.hasFuzhai){
									child.is_calculate = 1
								}
							}
						})
					})
					
					if(hasItem){
						result.push(res)
					}
				})
				this.mainData.entity_infos = result
				
				
				//判断父级未勾选的情况下，子集是否有其他勾选，没有的话，默认不勾选
				this.entityTreeList.map(parent=>{
					//父级未勾选
					if(parent.is_calculate==0){
						parent.childList.map(child=>{
							//子集取消勾选
							let hasSame =false
							this.entityTreeList.map(aaa=>{
								if(aaa.entity_name!=parent.entity_name){
									//有同名父级处于勾选
									if(aaa.entity_name==child.entity_name&&aaa.is_calculate==1){
										//子集勾选
										child.is_calculate = 1
										hasSame =true
									}
									aaa.childList.map(eee=>{
										//有同名子级处于勾选
										if(eee.entity_name==child.entity_name&&eee.is_calculate==1){
											//子集勾选
											child.is_calculate = 1
											hasSame =true
										}
									})
								}
							})
							if(!hasSame){
								child.is_calculate = 0
							}
						})
					}
				})

				let tempTreeList = []
				this.entityTreeList.map(parent=>{
					let has = false
					this.mainData.entity_infos.map(entity=>{
						if(parent.entity_name==entity.entity_name){
							has = true
						}
					})
					if(has){
						tempTreeList.push(parent)
					}
				})
				this.entityTreeList = tempTreeList
				
				//更新是否为受托人
				this.entityTreeList.map(parent=>{
					parent.is_shoutuo=this.checkIsShoutuo(parent.entity_name)
					parent.childList.map(child=>{
						child.is_shoutuo=this.checkIsShoutuo(child.entity_name)
					})
				})
			
			},
			//检测是否为受托人
			checkIsShoutuo(name){
				let result= 0
				this.mainData.entity_infos.map(res=>{
					if(res.entity_type_id==3||res.entity_type_id==4){
						if(res.basic_info.trustee_info.entity_name==name){
							result= 1
						}
						// res.basic_info.benef_info.map(ben=>{
						// 	if(result==1&&ben.entity_name==name){
						// 		result=2
						// 	}
						// })
					}
					
				})
				return result
			},
			//新、添加一级树
			addTreeParent(main,n){
        // console.log(main.entity_name,"---",n)
				if(main.entity_name!=''&main.entity_type_id!=''){
					let hasParent =false
					if(main.entity_type_id==2){//个人实体
						this.entityTreeList.map(res=>{
							res.childList.map(childitem=>{
								if(childitem.entity_name==main.entity_name){
									hasParent = true
								}
							})
						})
					}
					let hasEntity = false
					if(hasParent){
						return
					}
					
					let hasMain = false
					this.entityTreeList.map((res,index)=>{
						if(res.entity_name==main.entity_name){
							hasMain =true
							if(main.is_main!=undefined){
								res.is_main= main.is_main?main.is_main:0; //是否为主贷 1：是 0：否
							}
							if(main.is_calculate!=undefined){
								res.is_calculate= main.is_calculate?main.is_calculate:1; //是否纳入计算 1：是 0：否（勾选了就是 1）
							}
							if(main.entity_type_id!=undefined){
								res.entity_type_id= main.entity_type_id; //实体类型（个人、企业、家庭信托、单元信托）-传 id
							}
							if(main.allHas!=undefined){
								res.allHas= main.allHas?main.allHas:false;
							}
							if(main.is_relation_add!=undefined){
								res.is_relation_add = main.is_relation_add
							}
							res.entity_name= main.entity_name; //实体名称
							
							res.data='';
						}
					})
					if(hasMain){
						return
					}
					this.entityTreeList.push({ //添加树状实体
						is_main: main.is_main?main.is_main:0, //是否为主贷 1：是 0：否
						is_calculate: main.is_calculate?main.is_calculate:1, //是否纳入计算 1：是 0：否（勾选了就是 1）
						entity_type_id: main.entity_type_id, //实体类型（个人、企业、家庭信托、单元信托）-传 id
						entity_name: main.entity_name, //实体名称
						allHas: main.allHas?main.allHas:false,
						is_relation_add: main.is_relation_add?main.is_relation_add:'',
						data: main.entity_infos?main:'',
						childList: []
					})
				}
				
			},
			//新、添加二级树
			addTreeChildren(main,child){
				if(child.entity_name!=''&&child.entity_type_id!=''){
					let hasMain = false
					this.entityTreeList.map(res=>{
						if(res.entity_name==main.entity_name&&res.entity_type_id==main.entity_type_id){
							hasMain = true
							let hasChild =false
							res.childList.map(childitem=>{
								if(childitem.entity_name==child.entity_name){
									hasChild = true
								}
							})
							if(!hasChild){
								res.childList.push({ //添加树状实体
									is_main: 0, //是否为主贷 1：是 0：否
									is_calculate: main.is_calculate, //是否纳入计算 1：是 0：否（勾选了就是 1）
									entity_type_id: child.entity_type_id, //实体类型（个人、企业、家庭信托、单元信托）-传 id
									entity_name: child.entity_name, //实体名称
									allHas: child.allHas?child.allHas:false,
									data: '',
									childList: []
								})
							}
							if(child.entity_type_id==2){//个人实体
								let hasParent =false 
								let mainIndex = 0
								this.entityTreeList.map((res,index)=>{
									if(res.entity_name==child.entity_name){
										hasParent = true
										mainIndex =index
									}
								})
								//个人实体有父级、同时实体树一级有该实体，则删除该实体
								if(hasParent){
									this.entityTreeList.splice(mainIndex,1)
								}
							}
						}
					})
					if(!hasMain){
						this.addTreeParent(main,12)
						this.addTreeChildren(main,child)
					}
					
				}
			},
			
			//获取缓存数据
			getData() { 
				let mainData = localStorage.getItem("mainData")
				if (mainData != null && mainData != undefined) {
					this.mainData = JSON.parse(mainData)
					this.updateTreeListInfo()
				}
			
			},
			//显示新增贷款主体弹框
			showAddEntityMain() {
				this.tempLoanMainPurpose.entity_main = ''
				this.tempLoanMainPurpose.purpose_id = ''
				this.tempLoanMainPurpose.target_total = ''
				this.tempLoanMainPurpose.target_total = ''
				this.dialogVisible = true
			},
			changeEntityMain(item){
				
				this.tempChange.entity_old = item
				this.tempChange.entity_new = ''
				this.dialogChangeVisible = true
			},
			//确认更换贷款主体
			confirmChangeEntityMain() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						let entity_name_old = this.tempChange.entity_old.entity_name
						let entity_name_new = this.tempChange.entity_new.entity_name
						let old_loan_purpose = []
						let new_pawn_info = []
						this.mainData.entity_infos.map(res=>{
							if(res.entity_name ==entity_name_old){
								res.basic_info.debt_info.map(sss=>{
									sss.is_relend = 0
								})
								res.debt_info.map(sss=>{
									sss.is_relend = 0
								})
								res.loan_purpose.map(res22=>{
									if(res22.is_relend=='0'||res22.is_relend==0){
										old_loan_purpose.push(res22)
									}
								})
								new_pawn_info = JSON.parse(JSON.stringify(res.new_pawn_info))
								res.loan_purpose = []
								res.build_pawn_info = []
								res.new_pawn_info=[{
									pawn_type: "",
									income_total: "",
									index:-1,
									total: "",
                  rental_income:""
								}]
								res.is_main = 0
								res.basic_info.is_main = 0
							}
						})
						this.mainData.entity_infos.map(res=>{
							if(res.entity_name ==entity_name_new){
								res.new_pawn_info = new_pawn_info 
								res.basic_info.new_pawn_info = new_pawn_info
								
								let temploan= (this.getTypeIdLoanList(res.entity_type_id)).join(",");
								
								let tempPurpose = []
								old_loan_purpose.map(res=>{
									if(temploan.includes(res.purpose_id)){
										tempPurpose.push(res)
									}
								})
								
								if(tempPurpose.length>0){
									res.loan_purpose = tempPurpose 
								}else{
									res.loan_purpose = [{
										purpose_id: '',
										target_total: '',
										is_relend: 0,
										pawn_type:'',
										evaluate_value:'',
										building_cost:'',
										rent_total:'',
										presale_total:'',
                    rental_income:""
									}]
								}
								
								res.is_main = 1
								res.basic_info.is_main = 1
							}
						})
						this.dialogChangeVisible = false
						this.afterSaveData()
					}
				});
			},
			//根据实体类型获取相应贷款类型id
			getTypeIdLoanList(type_id){
				let temp = []
				this.loanTypeList2.map(lll=>{
					if(lll.entity_type_ids.includes(type_id)){
						temp.push(lll.id)
					}
				})
				return temp;
				
			},
			//确认添加贷款主体
			confirmAddEntityMain() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						// this.dialogVisible = false
						let entity_name = this.tempLoanMainPurpose.entity_main.entity_name
						this.mainData.entity_infos.map(res=>{
							if(res.entity_name ==entity_name){
								res.loan_purpose = [{
									purpose_id: this.tempLoanMainPurpose.purpose_id,
									target_total: this.tempLoanMainPurpose.target_total,
									is_relend: 0,
									pawn_type:'',
									evaluate_value:'',
									building_cost:'',
									rent_total:'',
									presale_total:''
								}]
								res.is_main = 1
								res.basic_info.is_main = 1
							}
						})
						this.dialogVisible = false
						this.afterSaveData()
					}
				});
			},
			toAsk(){
				this.$refs.xinxi.save(2)
				let routeData = this.$router.resolve({
				      path: '/help',
				    });
				    window.open(routeData.href, '_blank');
			},
      submitData() { //立即评估
        this.saveData()

        this.$refs['ruleForm1'].validate((valid) => {
          if (valid) {
            let loading=this.$loading()
            this.showConfirmDialog = false
            setTimeout(()=>{
              getLoanResult({
                data: JSON.stringify(this.mainData),
                cancel_entity_names:'',
                enlending:0,
              }).then(res => {
				console.log(res)
                this.showResultData = {data:{list:[]},msg:''}
                loading.close()
                if(res.code==400){
                  let data = res.data
                  if(data.type==1){
                    this.showResultData  = res
                    this.showResultDialog = true
                  }else{
                    this.$alert(res.msg, this.QWER('提示'), {
                      confirmButtonText: this.QWER('确定'),
                      dangerouslyUseHTMLString: true,
                      callback: action => { }
                    });
                  }

                }else{
                  this.$message({
                    type: 'success',
                    duration:this.showTime,
                    message: res.msg
                  })
                }

              }).catch(err => {
                loading.close()
                this.$message({
                  type: 'error',
                  duration:this.showTime,
                  message: err.msg
                })
              });
            },200)
          }
        });
      },
      toSaveDraft(){
        this.$confirm("Confirm to save drafts?", this.QWER('提示'), {
          confirmButtonText: this.QWER('确定'),
          cancelButtonText: this.QWER('取消'),
          type: 'warning'
        }).then(() => {
          this.toSaveDraftConfirm()
        }).catch(() => {})
      },
      toSaveDraftConfirm(){
        this.saveData()
        let loading=this.$loading()
        this.showConfirmDialog = false

        this.mainData.loan_main = []
        this.mainData.entity_infos.map(res=> {
          if (res.is_main == 1) {
            let tempMain = {
              entity_type_id: res.entity_type_id,
              entity_name: res.entity_name,
              loan_purpose: res.loan_purpose
            }
            this.mainData.loan_main.push(tempMain)
          }
        })
        setTimeout(()=>{
          this.is_loading_data=true
          saveDraft({
            sub_data: JSON.stringify(this.mainData),
            id:this.$route.query.drafts_id?this.$route.query.drafts_id:'',
          }).then(res => {
            console.log(res,'111')
            loading.close()
            this.is_loading_data=false
            if(res.code==400){
              this.$alert(res.msg, this.QWER('提示'), {
                confirmButtonText: this.QWER('确定'),
                dangerouslyUseHTMLString: true,
                callback: action => { }
              });
            }else if(res.code==401){

            }else{
              this.$message({
                type: 'success',
                duration:this.showTime,
                message: res.msg
              })
            }

          }).catch(err => {
            loading.close()
            this.is_loading_data=false
            this.$message({
              type: 'error',
              duration:this.showTime,
              message: err.msg
            })
          });
        },200)
      }
		}
	};
</script>

<style lang="scss" scoped>
	@import "@/baseScss/baseScss.scss";

	.body {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		min-width: 1706px;
	}

	.title-left-right {
		background-color: white;
		width: 100%;
		align-items: center;
		display: flex;
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 10px;
		padding-top: 10px;
		font-weight: bold;
		font-size: 18px;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid #eeeeee;
	}

	.title_main {
		font-weight: bold;
		font-size: 32px;
		color: #000000;
		line-height: 32px;
	}

	.left-bg {
		width: 400px;
		min-width: 400px;
		background-color: #eee;

		.status_bg {
			margin-left: 10px;
			font-size: 12px;
			color: #FF3737;
			padding-right: 15px;
		}

		.title_child {
			padding: 10px 10px 10px 35px;
			display: flex;
			align-items: center;
			flex-direction: row;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			margin-left: 2px;
			cursor: pointer;

			.title {
				color: #333333;
			}
			.main_bg {
				background-color: #FFFFFF;
				font-weight: 400;
				font-size: 12px;
				color: #DE7C2B;
				line-height: 22px;
				padding: 2px 6px;
				border-radius: 3px;
			}
		}

		.title_child_checked {
			padding: 10px 10px 10px 35px;
			display: flex;
			align-items: center;
			flex-direction: row;
			font-weight: 400;
			font-size: 14px;
			background-color: white;
			border-left: 2px solid #d08813;
			line-height: 20px;
			cursor: pointer;

			.title {
				color: #DE7C2B;
			}
			.main_bg {
				background-color: #eeeeee;
				font-weight: 400;
				font-size: 12px;
				color: #DE7C2B;
				line-height: 22px;
				padding: 2px 6px;
				border-radius: 3px;
			}
		}

		.left_check_no_bg {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 10px;
			margin-left: 2px;
			margin-top: 10px;
			cursor: pointer;

			.title_main {
				font-weight: bold;
				font-size: 16px;
				color: #333333;
				line-height: 20px;
				margin-left: 8px;
			}

			.main_bg {
				background-color: #FFFFFF;
				font-weight: 400;
				font-size: 12px;
				color: #DE7C2B;
				line-height: 22px;
				padding: 2px 6px;
				border-radius: 3px;
			}
		}

		.left_check_bg {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 10px;
			background-color: white;
			border-left: 2px solid #d08813;
			margin-top: 10px;

			.title_main {
				font-weight: bold;
				font-size: 16px;
				color: #DE7C2B;
				line-height: 20px;
				margin-left: 8px;
			}

			.main_bg {
				background-color: #eeeeee;
				font-weight: 400;
				font-size: 12px;
				color: #DE7C2B;
				line-height: 22px;
				padding: 2px 6px;
				border-radius: 3px;
			}
		}
	}
	.center-bg {
		flex: 1;
		background-color: white;
		border-left: 1px solid #F2F2F2;
		box-shadow: 0px 0px 12px 0px rgba(243, 243, 243, 0.5);
	}
	.right-bg {
		width: 400px;
		min-width: 400px;
		background-color: white;
		box-shadow: 0px 0px 20px 0px rgba(196, 196, 196, 0.35);

		.item-bg {
			border-bottom: 1px solid #eee;
			padding-bottom: 10px;
			padding-top: 10px;
		}

		.item-title1 {
			font-size: 14px;
			color: #666666;
			line-height: 22px;
		}

		.item-title2 {
			font-weight: bold;
			font-size: 16px;
			color: #333333;
			line-height: 22px;
			cursor: pointer;
		}
	}

	.tab-right-label {
		max-height: 20px;
		background: #FFF1F1;
		border-radius: 6px 6px 6px 0px;
		padding: 2px 6px;
		font-size: 12px;
		color: #FF3737;
		line-height: 20px;
		margin-left: 4px;
		margin-top: 4px;
	}

	::v-deep .el-tabs__nav {
		margin: 0px 50px;
	}

	::v-deep .el-tabs__item {
		color: #333333;
		font-size: 16px;
		height: 55px;
		line-height: 55px;
		min-width: 120px;
		text-align: center;
	}

	::v-deep .el-tabs__item.is-active {
		color: #DE7C2B; //选中
		font-size: 16px;
	}

	::v-deep .el-tabs__item:hover {
		color: #DE7C2B; //悬浮
		cursor: pointer;
		opacity: 1;
	}

	//下划线
	::v-deep .el-tabs__active-bar {
		width: 18px;
		height: 3px !important;
		border-radius: 2px;
	}

	/* 下划线颜色 */
	::v-deep .el-tabs__active-bar {
		background-color: #DE7C2B;
	}

	::v-deep .el-dialog {
		border-radius: 10px;
	}
  .result-item-name{
    line-height: 34px;
    padding-left: 10px;
    cursor: pointer;
  }
  .result-item-name:hover{
    background: #D8D8D8;
    color: #DE7C2B;
  }
	
</style>